import axios from "axios";
// import { log } from "joi-browser";
// import React from "react";


console.log(process.env.REACT_APP_BASEURL, "process.env.REACT_APP_BASEURL==");
// const baseURL = "http://127.0.0.1:8000/api/";

const baseURL = process.env.REACT_APP_BASEURL;

function request(url) {
  const headers = {
    Accept: "application/json",
  };
  const requestUrl = baseURL + url;
  return axios({
    method: "GET",
    url: requestUrl,
    headers,
  });
}
function postRequest(url, params) {
  const headers = {
    Accept: "application/json",
  };
  const requestUrl = baseURL + url;

  return axios({
    method: "POST",
    url: requestUrl,
    data: params,
    headers,
  });
}
// React.useEffect(() => {

//     request();
//     postRequest();

//  },[]);

export { request, postRequest };
