import React from "react";
import Grid from "@material-ui/core/Grid";
  // import { connect } from "react-redux";
import "./style.scss";
import BenefitsSection from "../BenefitsSection";
import { IoLibraryOutline } from "react-icons/io5";
import { TbReportSearch } from "react-icons/tb";
import { TbReport } from "react-icons/tb";
import FrequentlyAskedQsn from "../FrequentlyAskedQsn";
// import { loadServiceAction } from "../../store/actions/action";
import data from "../BenefitsSection/Smartdata";

const overviews = [
  {
    title: "View/Download Attendance Reports On the Go",
    description:"Reduce manual calculations for class teachers and school principals/ admins",
    icon: <TbReportSearch />,
  },
  {
    title: "Classroom Attendance Insights",
    description:"   Overview of the school attendance on a single dashboard with detailed reports for all stakeholders involved in the school",
    icon: <IoLibraryOutline />,
  },
  {
    title: "Biometric Attendance for Teachers",
    description:"Make integration & implementation of teacher attendance seamless with biometric authentication security forms",
    icon: <TbReport/>,
  },
];
const SmartAttendance = () => {  
    // useEffect(() => {
    //   props.loadServiceAction();
    // }, [props]);
  return (
    <>
      {/* // <Grid classNameName="" container spacing={4}>
    //   <Grid item xs={12} md={8}> */}
      <Grid className="textContent"></Grid>

      <div className="content-section-pratner">
        <h2 className="wow fadeInUp">Overview</h2>
      </div>
      <div className="modulesWrapperr">
        <div className="Container">
          <div id="modules" className="modules-section page-section">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 modules-sec">
                  <div id="mostmodul">
                    {overviews.map((overview, index) => (
                      <div key={index} className="modulbox">
                        <a
                          className="wow pulse"
                          data-toggle="modal"
                          data-target="#myModal101"
                          href="/"
                        >
                          <div></div>

                          <i className="icon-book-open icons iconss">
                            {overview.icon}
                          </i>
                          <h4>{overview.title}</h4>
                          <span>{overview.description}</span>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BenefitsSection data={data} />
      <FrequentlyAskedQsn />
    </>
  );
};

export default SmartAttendance;


