import React from "react";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import { connect } from "react-redux";
import BenefitsSection from "../BenefitsSection";
import FrequentlyAskedQsn from "../FrequentlyAskedQsn";
import { FaRoad } from "react-icons/fa";
import { MdEmojiTransportation } from "react-icons/md";
import { BsCarFrontFill } from "react-icons/bs";
import { loadServiceAction } from "../../store/actions/action";
import data from "../BenefitsSection/Gpsdata";
const overviews = [
  {
    title: "Detailed Vehicle Information",
    description:
      "The driver’s name, bus conductor’s name, staff in charge, all of their contact details, bus route, bus number, and even live location can be tracked using the IMEI number of the GPS in the vehicle.",
    icon: <BsCarFrontFill />,
  },
  {
    title: "Step-by-Step Process for Transport Management",
    description:
      "Simple systematic format to add school address, stoplocation, vehicle details and staff details to make the process streamlined and understandable .",
    icon: <MdEmojiTransportation />,
  },
  {
    title: "Automatic Route Creation",
    description:
      "Uses all the information fed into it to create the perfect route for the school buses based on the number of students automatically.",
    icon: <FaRoad />,
  },
];

const BusTracking = () => {
 
  return (
    <>
      {/* // <Grid className="" container spacing={4}>
    //   <Grid item xs={12} md={8}> */}
      <Grid className="textContent"></Grid>

      <div className="content-section-pratner">
        <h2 className="wow fadeInUp">Overview</h2>
      </div>
      <div className="modulesWrapperr">
        <div className="Container">
          <div id="modules" className="modules-section page-section">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 modules-sec">
                  <div id="mostmodul">
                    {overviews.map((overview, index) => (
                      <div key={index} className="modulbox">
                        <a
                          className="wow pulse"
                          data-toggle="modal"
                          data-target="#myModal101"
                          href="/"
                        >
                          <div></div>

                          <i className="icon-book-open icons iconss">
                            {overview.icon}
                          </i>
                          <h4>{overview.title}</h4>
                          <span>{overview.description}</span>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BenefitsSection data={data} />
      <FrequentlyAskedQsn />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    service: state.service,
  };
};
export default connect(mapStateToProps, { loadServiceAction })(BusTracking);

