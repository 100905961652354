import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import "./style.scss";
import CaseData from "../CaseData/Lms";
import { TbMessageReport } from "react-icons/tb";
import { MdAssessment,MdLibraryBooks } from "react-icons/md";
import TopSectionTitle from "../TopSectionTitle";
import FeaturesSection from "../FeaturesSection";
import FrequentlyAskedQsn from "../FrequentlyAskedQsn";
import { loadServiceAction } from "../../store/actions/action";
 
import InnerCaseLeft from "../../components/InnerCaseLeft"
import InnerCaseRight from "../../components/InnerCaseRight"
 const overviews = [
   {
     title: "Report & Tracking",
     description:
       "    Automated customized reports aiding in monitoring and tracking learner’s capability with regards to attendance, assignments, results, and certifications",
     icon: <TbMessageReport />,
   },
   {
     title: "Assessments",
     description:
       "Provide quality and effective assessments to thestudents and track their progress. Take corrective measures and actions as and when necessary.",
     icon: <MdAssessment />,
   },
   {
     title: "Course Creation & Management",
     description:
       " Create courses effortlessly and add these courses to the course library. You can allow students to learn  as per their own pace by marking target specific  courses.",
     icon: <MdLibraryBooks />,
   },
 ];
const LMS = () => {
  
  return (
    <>
      <Suspense fallback={null}>
        {/* //{" "}
      <Grid className="" container spacing={4}>
        //{" "}
        <Grid item xs={12} md={8}> */}
        <Grid className="textContent"></Grid>
        <div className="content-section-pratner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <TopSectionTitle
                  subtitle="What is Learning Management System?"
                  body="A Learning Management System is an online integrated solution
                  used for creating, delivering, tracking, and reporting
                  educational courses and outcomes. It is used to support hybrid
                  learning in the educational institutions where the teachers
                  can teach the students offline in classrooms as well as online
                  via software. Schools use LMS software to plan, implement,
                  facilitate, assess, and monitor student learning. All of these
                  activities are conducted behind a virtual wall that provides a
                  measure of authentication, security, and privacy."
                />
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="content-section-pratner">
          <h2 className="wow fadeInUp">Overview</h2>
        </div>
        <div className="modulesWrapperr">
          <div className="Container">
            <div id="modules" className="modules-section page-section">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 modules-sec">
                    <div id="mostmodul">
                      {overviews.map((overview, index) => (
                        <div key={index} className="modulbox">
                          <a
                            className="wow pulse"
                            data-toggle="modal"
                            data-target="#myModal101"
                            href="/"
                          >
                            <div></div>

                            <i className="icon-book-open icons iconss">
                              {overview.icon}
                            </i>
                            <h4>{overview.title}</h4>
                            <span>{overview.description}</span>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {CaseData &&
          CaseData.map((integrations, index) => {
            return (
              <InnerCaseLeft
                key={index}
                img={integrations?.image1}
                title={integrations.title1}
                listItem={[
                  integrations.Case1point1 && {
                    item: integrations.Case1point1,
                  },
                  integrations.Case1point2 && {
                    item: integrations.Case1point2,
                  },
                  integrations.Case1point3 && {
                    item: integrations.Case1point3,
                  },
                ]}
              />
            );
          })}
        ;
        {CaseData &&
          CaseData.map((integrations1, index) => {
            return (
              <InnerCaseRight
                key={index}
                title={integrations1.title2}
                listItem={[
                  integrations1.Case2point1 && {
                    item: integrations1.Case2point1,
                  },
                  integrations1.Case2point2 && {
                    item: integrations1.Case2point2,
                  },
                  integrations1.Case2point3 && {
                    item: integrations1.Case2point3,
                  },
                ]}
                img={integrations1?.image2}
              />
            );
          })}
        ;
        {CaseData &&
          CaseData.map((integrations, index) => {
            return (
              <InnerCaseLeft
                key={index}
                img={integrations?.image3}
                title={integrations.title3}
                listItem={[
                  integrations.Case3point1 && {
                    item: integrations.Case3point1,
                  },
                  integrations.Case3point2 && {
                    item: integrations.Case3point2,
                  },
                  integrations.Case3point3 && {
                    item: integrations.Case3point3,
                  },
                ]}
              />
            );
          })}
        ;
        {CaseData &&
          CaseData.map((integrations1, index) => {
            return (
              <InnerCaseRight
                key={index}
                title={integrations1.title4}
                listItem={[
                  integrations1.Case4point1 && {
                    item: integrations1.Case4point1,
                  },
                  integrations1.Case4point2 && {
                    item: integrations1.Case4point2,
                  },
                  integrations1.Case4point3 && {
                    item: integrations1.Case4point3,
                  },
                ]}
                img={integrations1?.image4}
              />
            );
          })}
        <div
          className="w-full flex justify-center lg:justify-start mb-6 mt-6"
          style={{ marginLeft: "40%", marginBottom: "50px" }}
        >
          <Link to="/contact-us" className="cBtn">
            Get Started
          </Link>
        </div>
        <FeaturesSection />
        <FrequentlyAskedQsn />
        {/* //{" "}
        </Grid>
        //{" "}
      </Grid> */}
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    service: state.service,
  };
};
export default connect(mapStateToProps, { loadServiceAction })(LMS);
