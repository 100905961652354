import React, { Fragment } from "react";

import StudentTracking from "../../components/StudentTracking";
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";


const Gallery = () => {

  return (
    <Fragment>
      <BreadCrumb
        pagename="Student Tracking System"
    
      />

      <StudentTracking className="hasMb" />
      <Footer />
    </Fragment>
  );
};



export default Gallery;
