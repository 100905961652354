import React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from "@material-ui/core/Grid";

import abdtdotShape from '../../images/abdtdotshape.png';
import introLeft from "../../images/Who-We-Are.jpg";

import './style.scss';

class AboutIntro extends React.Component {
    render(){


    const {
        about_feature_left_image,
        about_feature_title1,
        about_feature_title2,
        about_feature_title3,
        about_feature_title4,
        about_feature_des1,
        about_feature_des2,
        about_feature_des3,
        about_feature_des4,
    } = this.props.about;

    console.log(this.props)


    return (
      <Grid className="aboutIntroWrapper">
        <Grid container spacing={4} alignItems="center" className="container">
          <Grid item xs={12} md={5}>
            <Grid className="thumb">
              <img
                src={
                  about_feature_left_image
                    ? about_feature_left_image
                    : introLeft
                }
                alt="leftImage"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid className="introList">
              <span>01</span>
              <h4>
                {about_feature_title1 ? about_feature_title1 : "Our History"}
              </h4>
              <p>
                {about_feature_des1
                  ? about_feature_des1
                  : "Scholar ERP was founded in 2010 by a team of experienced education professionals who recognized the need for a comprehensive, all-in-one education management system. Since then, we have grown to serve institutions of all sizes and types, from small private schools to large universities. "}
              </p>
            </Grid>
            <Grid className="introList">
              <span>02</span>
              <h4>
                {about_feature_title2 ? about_feature_title2 : "Our Team"}
              </h4>
              <p>
                {about_feature_des2
                  ? about_feature_des2
                  : "Backed with strong core principles and a motto to strive for excellence, our vision is clear and defined – to provide propulsive learning opportunities to over 26 million children by 2026."}
              </p>
            </Grid>
            <Grid className="introList">
              <span>03</span>
              <h4>
                {about_feature_title3
                  ? about_feature_title3
                  : "Our Values"}
              </h4>
              <p>
                {about_feature_des3
                  ? about_feature_des3
                  : "At Scholar ERP, we are guided by a set of core values that inform everything we do:Innovation: We are always looking for new and better ways to help our clients achieve their goals. User-Friendliness: We believe that software should be easy to use and accessible to everyone."}
              </p>
            </Grid>
            <Grid className="introList">
              <span>04</span>
              <h4>
                {about_feature_title4
                  ? about_feature_title4
                  : "Our Commitment"}
              </h4>
              <p>
                {about_feature_des4
                  ? about_feature_des4
                  : "At Scholar ERP, we are committed to providing high-quality products and services that meet the unique needs of educational institutions. We believe that education is the key to a better future, and we are proud to support institutions that share our commitment to excellence."}
              </p>
            </Grid>
          </Grid>
        </Grid>
        <img src={abdtdotShape} alt="abdtdotShape" className="abdtdotShape" />
      </Grid>
    );
    }
};

export default withTranslation('common')(AboutIntro); 