import React, {Fragment, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FontAwesome from "../UiStyle/FontAwesome";

import Logo from '../../images/logo.svg';
import './style.scss';

const menulists = [
  {
    id: 1,
    name: "Home",
    link: "/home",
  },
  {
    id: 2,
    name: "Solutions",
    submenu: [
      {
        id: 1,
        name: "Institute Enterprise Resource Planning",
        link: "/institute-erp",
      },
      {
        id: 2,
        name: "Smart Attendance Management System",
        link: "/smart-attendance",
      },
      {
        id: 3,
        name: "Learning Management System",
        link: "/lms",
      },
      {
        id: 4,
        name: "GPS Bus Tracking",
        link: "/bus-tracking",
      },
      {
        id: 5,
        name: "Online Fee Payment",
        link: "/fees-management",
      },
      {
        id: 6,
        name: "School Management Software",
        link: "/school-software",
      },
    ],
  },
  {
    id: 3,
    name: "Modules",
    submenu: [
      {
        id: 1,
        name: "Admission Management System",
        link: "/admissions",
      },
      {
        id: 2,
        name: "Learning Management System",
        link: "/lms",
      },
      {
        id: 3,
        name: "Student Information System",
        link: "/student-information",
      },
      {
        id: 4,
        name: "Assessment Management System",
        link: "/assessment",
      },
      {
        id: 5,
        name: "Fees Management System",
        link: "/fees-management",
      },
      {
        id: 6,
        name: "Student Tracking System",
        link: "/student-tracking",
      },
      {
        id: 7,
        name: "Reports",
        link: "/reports",
      },
      {
        id: 8,
        name: "Exam Planner",
        link: "/exam-planner",
      },
    ],
  },
  {
    id: 4,
    name: "About Us",
    submenu: [
      {
        id: 1,
        name: "Who We Are",
        link: "/gallery",
      },
      {
        id: 2,
        name: "Our Team",
        link: "/blog-details/1",
      },
      {
        id: 3,
        name: "Blog",
        link: "/portfolio-single",
      },
      {
        id: 4,
        name: "Portfolio",
        link: "/portfolio-single",
      },
    ],
  },
  {
    id: 5,
    name: "Plans",
    link: "/plans",
  },
  {
    id: 6,
    name: "Blog",
    link: "/blog",
  },
];

const useStyles = makeStyles(theme => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));


const MobileMenu = ({showMobile, setShowMobile}) => {
    const classes = useStyles();

    const [menuId, setActiveMid] = useState();

    function handleExpandClick(id) {
        setActiveMid(id);
        if (menuId === id){
            setActiveMid()
        }
    }

    return(
        <Fragment>
            <Grid className={`mobileMenu ${showMobile ? '' : 'hidden'}`}>
                <Grid className="sLogo">
                    <Link to="/"><img src={Logo} alt="logo"/></Link>
                </Grid>
                <List>
                    {menulists && menulists.map((item,index)=> {
                        return(
                            <ListItem key={index}>
                                {!item.submenu
                                    ? <Link to={item.link}>{item.name}</Link>
                                    : <Grid
                                        className="mItem"
                                        onClick={() => handleExpandClick(item.id)}
                                    >
                                        <span>{item.name}</span>
                                        <Grid
                                            className={clsx(classes.expand, {
                                                [classes.expandOpen]: menuId === item.id,
                                            })}
                                        >
                                            <FontAwesome name="angle-down" />
                                        </Grid>
                                    </Grid>
                                }
                                {item.submenu ? item.submenu.map(subitem => {
                                    return(
                                        <Collapse key={subitem.id} className="subMenu" in={menuId === item.id} timeout="auto" unmountOnExit>
                                            <Link to={subitem.link}>{subitem.name}</Link>
                                        </Collapse>
                                    )
                                }) : ''}
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>
            <Grid onClick={() => setShowMobile(!showMobile)} className={`menuHoverlay ${showMobile ? 'show' : ''}`}></Grid>
        </Fragment>
    )
};

export default MobileMenu;