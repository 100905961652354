import React, { Fragment} from "react";

import AdmissionManagement from "../../components/AdmissionManagement";
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";


const Gallery = () => {
 
  return (
    <Fragment>
      <BreadCrumb
        pagename="Admissions Management"
      
      />
     
      <AdmissionManagement className="hasMb" />
      <Footer />
    </Fragment>
  );
};



export default Gallery;
