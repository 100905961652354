import React from "react";
// import {Link} from "react-router-dom";
import "./style.scss";
const Plans = () => {
    return (
      <>
        <div id="generic_price_table">
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="price-heading clearfix">
                    <h1>Plans</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="generic_content clearfix">
                    <div className="generic_head_price clearfix">
                      <div className="generic_head_content clearfix">
                        <div className="head_bg"></div>
                        <div className="head">
                          <span>Basic</span>
                        </div>
                      </div>

                      <div className="generic_price_tag clearfix">
                        <span className="price">
                          {/* <span className="sign">$</span>
                        <span className="currency">Free</span> */}
                          <span className="cent">Free</span>
                          {/* <span className="month">/MON</span> */}
                        </span>
                      </div>
                    </div>

                    <div className="generic_feature_list">
                      <ul>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          </span>
                          ScholarErp Application for teachers and students
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          </span>
                          Unlimited Two-Way Live Class and recording
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          </span>
                          Store and share study material
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          </span>
                          Online Tests (auto graded and subjective)
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          </span>
                          Teacher-Student Chat & Discussions
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          </span>
                          Dashboard for Institute Administration
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          </span>
                          Classroom and teacher management (add/remove
                          classroom, teacher, students)
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          </span>
                          Teacher and Student Announcements
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          </span>
                          Single Admin Access
                        </li>
                      </ul>
                    </div>

                    <div className="generic_price_btn clearfix">
                      <a className="" href="contact-us">
                        Sign up
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="generic_content active clearfix">
                    <div className="generic_head_price clearfix">
                      <div className="generic_head_content clearfix">
                        <div className="head_bg"></div>
                        <div className="head">
                          <span>Advanced</span>
                        </div>
                      </div>

                      <div className="generic_price_tag clearfix">
                        <span className="price">
                          {/* <span className="sign">$</span>
                        <span className="currency">199</span> */}
                          <span className="cent">Paid Plan</span>
                          {/* <span className="month">/MON</span> */}
                        </span>
                      </div>
                    </div>

                    <div className="generic_feature_list">
                      <ul>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          </span>
                          Everything in the Basic Plan
                        </li>

                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          </span>{" "}
                          Classroom Monitoring
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>{" "}
                          Student Attendance Tracking
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>{" "}
                          Student Performance Reports
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>{" "}
                          Insightful Statistics
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>{" "}
                          Fee Management
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>{" "}
                          Admission Management
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>{" "}
                          Transport management
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>{" "}
                          Hostel management
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>{" "}
                          Inventory Management
                        </li>
                      </ul>
                    </div>

                    <div className="generic_price_btn clearfix">
                      <a className="" href="contact-us">
                        Sign up
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="generic_content clearfix">
                    <div className="generic_head_price clearfix">
                      <div className="generic_head_content clearfix">
                        <div className="head_bg"></div>
                        <div className="head">
                          <span>Pro</span>
                        </div>
                      </div>

                      <div className="generic_price_tag clearfix">
                        <span className="price">
                          {/* <span className="sign">$</span>
                        <span className="currency">299</span> */}
                          <span className="cent">Paid Plan</span>
                          {/* <span className="month">/MON</span> */}
                        </span>
                      </div>
                    </div>

                    <div className="generic_feature_list">
                      <ul>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>{" "}
                          Everything in the Advanced Plan
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>{" "}
                          Digital Learning Content
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>{" "}
                          Many more
                        </li>
                      </ul>
                    </div>

                    <div className="generic_price_btn clearfix">
                      <a className="" href="contact-us">
                        Sign up
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="tm-tfi-max-width tm-bgcr-bl-2 p-20 relative  rounded-xl p-10 mb-10">
          <div className="footer-banner">
            <h2 className="tm-hdg-24  lg:tm-hdg-48 tm-lh-100 tm-cr-wh-1 mb-16">
              50 Reasons to choose ScholarErp
            </h2>
            <div className="text-center know-div lg:text-left">
              <a
                href="/reasons"
                className="tm-bgcr-wh-1 px-10 headd lg:px-4 p-4 tm-para-16 tm-cr-bl-2 rounded-lg lg:ml-0 cursor-pointer border-0"
                id="fp-lp-footerbutton"
              >
                Know here
              </a>
            </div>
          </div>
        </div>
      </>
    );
};

export default Plans;
 