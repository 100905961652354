import React from "react";
import "./style.scss";
import { NavLink } from "react-router-dom";
const baseUri = process.env.PUBLIC_URL;

function SolutionsDropdown({ dWidth }) {
  return (
    <div className={`row mega_menu_inner d-flex about_dropdown ${dWidth}`}>
      <div
        className="maincontainersolution"
        style={{ width: "100%", justifyContent: "center" }}
      >
    <div className="innerfirstsolutioncontainer innersolutioncontainer">
          <div className="namecontainer">
            <NavLink
              exact
              title="Institute Enterprise Resource Planning"
              className="nav-link"
              to="/institute-erp"
            >
              <div>
                <img src={baseUri + "/images/Point_Solutions.svg"} alt="simg" />
              </div>
              <div>
                <p>Institute Enterprise Resource Planning</p>
              </div>
            </NavLink>
          </div>

          <div className="namecontainer">
            <NavLink
              exact
              title="GPSBusTracking"
              className="nav-link"
              to="/bus-tracking"
            >
              <div>
                <img src={baseUri + "/images/Point_Solutions.svg"} alt="simg" />
              </div>
              <div>
                <p> GPS Bus Tracking</p>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="innersecondsolutioncontainer innersolutioncontainer">
          <div className="namecontainer">
            <NavLink
              exact
              title="SmartAttendanceManagement "
              className="nav-link"
              to="/smart-attendance"
            >
              <div>
                <img src={baseUri + "/images/Point_Solutions.svg"} alt="simg" />
              </div>
              <div>
                <p>Smart Attendance Management System </p>
              </div>
            </NavLink>
          </div>

          <div className="namecontainer">
            <NavLink
              exact
              title="OnlineFeePayment"
              className="nav-link"
              to="/fees-management"
            >
              <div>
                <img src={baseUri + "/images/Point_Solutions.svg"} alt="simg" />
              </div>
              <div>
                <p>Online Fee Payment</p>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="innerthirdsolutioncontainer innersolutioncontainer">
          <div className="namecontainer">
            <NavLink
              exact
              title="LearningManagementSystem"
              className="nav-link"
              to="/lms"
            >
              <div>
                <img src={baseUri + "/images/Point_Solutions.svg"} alt="simg" />
              </div>
              <div>
                <p>Learning Management System</p>
              </div>
            </NavLink>
          </div>
          <div className="namecontainer">
            <NavLink
              exact
              title="SchoolManagementSoftware "
              className="nav-link"
              to="/school-software"
            >
              <div>
                <img src={baseUri + "/images/Point_Solutions.svg"} alt="simg" />
              </div>
              <div>
                <p>School Management Software </p>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SolutionsDropdown;
