import React,{Suspense} from "react";
// import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import { connect } from "react-redux";
import { TbReportAnalytics } from "react-icons/tb";
import { BsGraphUpArrow } from "react-icons/bs";
import { MdSpaceDashboard } from "react-icons/md";
import FrequentlyAskedQns from "../FrequentlyAskedQsn";
import BenefitsSection from "../BenefitsSection";
import { loadServiceAction } from "../../store/actions/action";
import TopSectionTitle from "../TopSectionTitle";
import data from "../BenefitsSection/Reportsdata";
const overviews = [
  {
    title: "Staff Productivity Reports",
    description:
      " Automated customized reports aiding in monitoring and tracking learner’s capability with regards to attendance, assignments, results, and certifications",
    icon: <TbReportAnalytics />,
  },
  {
    title: "Student Performance Reports",
    description:
      "   Provide quality and effective assessments to the  students and track their progress. Take corrective  measures and actions as and when necessary.",
    icon: <BsGraphUpArrow />,
  },
  {
    title: "Customized Dashboards",
    description:
      "  Create courses effortlessly and add these courses to  the course library. You can allow students to learn as  per their own pace by marking target specific courses.",
    icon: <MdSpaceDashboard />,
  },
];
const Reports = () => {
   
  return (
    <>
      <Suspense fallback={null}>
        {/* <Grid className="" container spacing={4}>
        <Grid item xs={12} md={8}> */}
        <Grid className="textContent"></Grid>
        <div className="content-section-pratner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <TopSectionTitle
                  subtitle="What Are The Reports & Analytics?"
                  body="  Reports and analytics help to provide a 360 degree overview of
                  the institution. The institute can get dynamic reports from
                  any module and assess the shortcomings to take corrective
                  actions. A school can create various reports like admission,
                  results, fees etc and keep a 360 degree track on the overall
                  functioning of the institution"
                />

              </div>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="content-section-pratner">
          <h2 className="wow fadeInUp">Overview</h2>
        </div>
        <div className="modulesWrapperr">
          <div className="Container">
            <div id="modules" className="modules-section page-section">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 modules-sec">
                    <div id="mostmodul">
                      {overviews.map((overview, index) => (
                        <div key={index} className="modulbox">
                          <a
                            className="wow pulse"
                            data-toggle="modal"
                            data-target="#myModal101"
                            href="/"
                          >
                            <div></div>

                            <i className="icon-book-open icons iconss">
                              {overview.icon}
                            </i>
                            <h4>{overview.title}</h4>
                            <span>{overview.description}</span>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BenefitsSection data={data} />
        <FrequentlyAskedQns />
      </Suspense>
      {/* </Grid>
      </Grid> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    service: state.service,
  };
};
export default connect(mapStateToProps, { loadServiceAction })(Reports);
