import React, { Fragment } from "react";

import SchoolSoftware from "../../components/SchoolSoftware";
import Footer from "../../components/Footer";
import Banner from "../../components/Banner";

import Image from "../../images/banner.png";

const Gallery = () => {

  return (
    <Fragment>
      <Banner
        title="School Management Software"
        pagedescription="Track, manage and convert admission enquiries easily and
                effectively. Set up admission enquiry form and collect enquiries
                from any sources."
        bannerImg={Image}
      />

      <SchoolSoftware className="hasMb" />
      <Footer />
    </Fragment>
  );
};



export default Gallery;
