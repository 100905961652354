import React, { Fragment} from "react";

import Reports from "../../components/Reports";
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";


const Gallery = () => {
 
  return (
    <Fragment>
      <BreadCrumb
        pagename="Reports"
  
      />

      <Reports className="hasMb" />
      <Footer />
    </Fragment>
  );
};



export default Gallery;
