import React, { Fragment} from "react";

import ExamPlanner from "../../components/ExamPlanner";
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";


const Gallery = () => {

  return (
    <Fragment>
      <BreadCrumb
        pagename="Exam Planner"
      
      />

      <ExamPlanner className="hasMb" />
      <Footer />
    </Fragment>
  );
};



export default Gallery;
