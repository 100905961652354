import React from "react";
import { NavLink } from "react-router-dom";
const baseUri = process.env.PUBLIC_URL;

function AboutDesktopDropdown({ dWidth }) {
  return (
    <div>
      <div className={`mega_menu_inner d-flex about_dropdown ${dWidth}`}>
        <ul className="dropdown-menu abt_ul" style={{ maxWidth: "100%" }}>
          <li className="nav-item d-flex">
            <div style={{ width: "70%" }}>
              <ul className="dropdown-menu scroll">
                <li
                  className="nav-item abt_list_item"
                  style={{ borderTop: "0px", borderLeft: "0px" }}
                >
                  <NavLink
                    to="/about-us"
                    exact
                    className="item abt_nav_icon d-flex"
                  >
                    <div className="abt_drop_item">
                      <img
                        src={baseUri + "/images/team.png"}
                        alt="simg"
                        style={{ height: "40px", width: "40px" }}
                      />
                    </div>
                    <div
                      className="text-left"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span className="text f_size_18 f_600">Who We Are ?</span>
                    </div>
                  </NavLink>
                </li>
                <li
                  className="nav-item abt_list_item"
                  style={{ borderTop: "0px", borderRight: "solid #eaeaea 2px" }}
                >
                  <NavLink
                    to="/team"
                    exact
                    className="item abt_nav_icon d-flex"
                  >
                    <div className="abt_drop_item">
                      <img src={baseUri + "/images/team.svg"} alt="simg" />
                    </div>
                    <div
                      className="text-left"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span className="text f_size_18 f_600">Our Team</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
              <ul className="dropdown-menu scroll">
                <li
                  className="nav-item abt_list_item"
                  style={{ borderLeft: "0px", borderBottom: "0px" }}
                >
                  <NavLink
                    to="/blog"
                    exact
                    className="item abt_nav_icon d-flex"
                  >
                    <div className="abt_drop_item">
                      <img
                        src={baseUri + "/images/blog.png"}
                        alt="simg"
                        style={{ height: "40px", width: "40px" }}
                      />
                    </div>
                    <div
                      className="text-left"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span className="text f_size_18 f_600">Blog</span>
                    </div>
                  </NavLink>
                </li>
                <li
                  className="nav-item abt_list_item"
                  style={{
                    borderBottom: "0px",
                    borderRight: "solid #eaeaea 2px",
                  }}
                >
                  <NavLink
                    to="/portfolio"
                    exact
                    className="item abt_nav_icon d-flex"
                  >
                    <div className="abt_drop_item">
                      <img
                        src={baseUri + "/images/portfolio.png"}
                        style={{ height: "40px", width: "40px" }}
                        alt="simg"
                      />
                    </div>
                    <div
                      className="text-left"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span className="text f_size_18 f_600">Portfolio</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="abt_contact_info">
              <ul className="dropdown-menu">
                <li className="nav-item" style={{ display: "flex" }}>
                  <img src={baseUri + "/images/call.png"} alt="simg" />
                  <a href="tel:+13153595857">
                    <span className="text contact_info_text">
                      +1 315 359 5857
                    </span>
                  </a>
                </li>

                <li className="nav-item" style={{ display: "flex" }}>
                  <img src={baseUri + "/images/email.png"} alt="simg" />
                  <a href="mailto:scholarerp.com">
                    <span className="text contact_info_text">
                      scholarerp.com
                    </span>
                  </a>
                </li>
                <li className="nav-item" style={{ display: "flex" }}>
                  <img src={baseUri + "/images/linkedin.png"} alt="simg" />
                  <a href="https://in.linkedin.com/company/jstechno">
                    <span className="text contact_info_text">ScholarERP </span>
                  </a>
                </li>
                <li className="nav-item" style={{ display: "flex" }}>
                  <img src={baseUri + "/images/fb.png"} alt="simg" />
                  <a href="https://www.facebook.com/jstechno/">
                    <span className="text contact_info_text">
                      ScholarERP Pvt ltd.{" "}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AboutDesktopDropdown;
