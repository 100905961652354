import React, { Fragment } from "react";

import StudentInfor from "../../components/StudentInfor";
import Footer from "../../components/Footer";
import Banner from "../../components/Banner";

import Image from "../../images/inforBanner.png";

const Gallery = () => {

  return (
    <Fragment>
      <Banner
        subtitle="Student Information Management System"
        title="Seamless Student Information Retrieval and Management"
        pagedescription="Keep track of student data, including personal information, academic performance, attendance records, and more."
        bannerImg={Image}
      />

      <StudentInfor className="hasMb" />
      <Footer />
    </Fragment>
  );
};



export default Gallery;
