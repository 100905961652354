import React, { Fragment} from "react";

import Assessment from "../../components/Assessment";
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";


const Gallery = () => {

  return (
    <Fragment>
      <BreadCrumb
        pagename="Assessment"
     
      />

      <Assessment className="hasMb" />
      <Footer />
    </Fragment>
  );
};


export default Gallery;
