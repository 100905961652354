import React,{Suspense} from "react";
// import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import { connect } from "react-redux";
import { IoBookSharp } from "react-icons/io5";
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import { MdArtTrack } from "react-icons/md";
import FrequentlyAskedQns from "../FrequentlyAskedQsn";
import BenefitsSection from "../BenefitsSection";
import { loadServiceAction } from "../../store/actions/action";
import TopSectionTitle from "../TopSectionTitle";
import data from "../BenefitsSection/Examdata";

const overviews = [
  {
    title: "Curriculum Management",
    description:
      "   ScholarErp helps in maintaining proper time stamps of each and every resource which also includes the time they have worked beyond their working hours at the end of the day.",
    icon: <IoBookSharp />,
  },
  {
    title: "Enhance Instruction Delivery",
    description:
      "  ScholarErp lesson planning system helps in a unique and distinct way to teach every subject, unlike traditional textbooks.When there is a roadmap for teachers to follow.",
    icon: <AiOutlineDeliveredProcedure />,
  },
  {
    title: "Syllabus Tracking",
    description:
      "   ScholarErp helps to track the progress of the syllabus. By tracking the syllabus, the teacher can get an overview of whether the ultimate goal of the lesson plan will be accomplished or not.",
    icon: <MdArtTrack />,
  },
];
const ExamPlanner = () => {
  
  return (
    <>
      <Suspense fallback={null}>
        {/* <Grid className="" container spacing={4}>
      <Grid item xs={12} md={8}> */}
        <Grid className="textContent"></Grid>
        <div className="content-section-pratner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <TopSectionTitle
                  subtitle=" What is the Exam Planner System?"
                 
                  body="    The Exam Planner system helps to deliver an immersive learning
                  experience for the students. The System helps to simplify the
                  learning by the teachers by allowing the teachers to plan
                  digital exams, lessons, notes and other documents. Teachers
                  can create relevant digital content, notes, visual diagrams,
                  web links, etc. that can be easily shared with students to
                  meet their learning needs and enhance their unique learning
                  abilities. "
                />
           
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="content-section-pratner">
          <h2 className="wow fadeInUp">Overview</h2>
        </div>
        <div className="modulesWrapperr">
          <div className="Container">
            <div id="modules" className="modules-section page-section">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 modules-sec">
                    <div id="mostmodul">
                      {overviews.map((overview, index) => (
                        <div key={index} className="modulbox">
                          <a
                            className="wow pulse"
                            data-toggle="modal"
                            data-target="#myModal101"
                            href="/"
                          >
                            <div></div>

                            <i className="icon-book-open icons iconss">
                              {overview.icon}
                            </i>
                            <h4>{overview.title}</h4>
                            <span>{overview.description}</span>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BenefitsSection data={data} />
        <FrequentlyAskedQns />
      </Suspense>
    </>
  );
};


const mapStateToProps = (state) => {
  return {
    service: state.service,
  };
};
export default connect(mapStateToProps, { loadServiceAction })(ExamPlanner);
