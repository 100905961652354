import images1 from "../../images/lms.png";

const RightCaseData = [
  {
    pageName: "Student Information System",
   
    title1: "Easy Mighty Access",
    Case1point1: "Tremendous market size",
    Case1point2: "Growth and future potential",
    Case1point3: "Increasing trend towards technology adoption",
    Case1point4: "Minimal investment",
        Case1point5: "Assured revenue stream",
        image1: images1,
    title2: "Effortless Management",
    Case2point1: " Tremendous market size",
    Case2point2: "Growth and future potential",
    Case2point3: "Increasing trend towards technology adoption",
    Case2point4: "Minimal investment",
    Case2point5: "Consistent source of recurring income",
  },
];

export default RightCaseData;
