import React, {Fragment, useEffect} from 'react';
  import {connect} from 'react-redux';

import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";
import HireUs from "../../components/HireUs";
import { loadServiceAction } from "../../store/actions/action";
    import 'bootstrap/dist/css/bootstrap.min.css';
import ModulesSection from '../../components/ModulesSection';

// const modules = [
//   {
//     title: "Module 1",
//     description: "Description of Module 1",
//     icon: "icon3.png",
//   },
//   {
//     title: "Module 2",
//     description: "Description of Module 2",
//     icon: "icon2.png",
//   },
//   {
//     title: "Module 3",
//     description: "Description of Module 3",
//     icon: "icon3.png",
//   },
//   {
//     title: "Module 4",
//     description: "Description of Module 4",
//     icon: "icon2.png",
//   },
// ];
const Services = (props) => {

    useEffect(() => {
        props.loadServiceAction();
        
    });

    return (
      <Fragment>
        <BreadCrumb
          pagename="All Modules"
          title={
            props.service.service_banner_title
              ? props.service.service_banner_title
              : ""
          }
        />
        <ModulesSection />
        {/* <div className="container">
          <div className="row">
            {modules.map((module, index) => (
              <div key={index} className="col-lg-3 col-md-8">
                <div className="card">
                  <img
                    src={module.icon}
                    alt={module.title}
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h3 className="card-title">{module.title}</h3>
                    <p className="card-text">{module.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}

        <HireUs />
        <Footer />
      </Fragment>
    );
};
const mapStateToProps = state => {
    return {
        service: state.service
    }
};
export default connect(mapStateToProps, { loadServiceAction })(Services);





   

