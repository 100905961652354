import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import { loadServiceAction } from "../../store/actions/action";
import "./style.scss";
// import "./simple-line-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { TbReportSearch } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { GoFileDirectory } from "react-icons/go"; 
import { SiMicrosoftacademic } from "react-icons/si";
import { BsPeopleFill} from "react-icons/bs";
import { AiOutlineSafetyCertificate } from "react-icons/ai";

const modules = [
  {
    link: "/admissions",
    title: "Student Profile",

    icon: <CgProfile />,
  },
  {
    link: "/assessment",
    title: "Student Directory",

    icon: <GoFileDirectory />,
  },
  {
    link: "/reports",
    title: "Personal Information",

    icon: <TbReportSearch />,
  },
  {
    link: "/admissions",
    title: "Academic Perfomance",

    icon: <SiMicrosoftacademic />,
  },
  {
    link: "/assessment",
    title: "Attendance Information",

    icon: <BsPeopleFill />,
  },
  {
    link: "/reports",
    title: "Behavioral badges",

    icon: <AiOutlineSafetyCertificate />,
  },
];

const ModulesSection = () => {

  return (
    <Grid className="featuresWrapper">
      <div className="container">
        <p>Features</p>
        <div id="modules" className="modules-section page-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 modules-sec">
                {" "}
                {modules.map((module, index) => (
                  <div key={index} id="mostmodul">
                    <Link to={module.link}>
                      <div className="modulbox">
                        <i className="icon-book-open icons">{module.icon} </i>
                        <h4>{module.title} </h4>
                      </div>
                    </Link>
                  </div>
                ))}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    service: state.service,
  };
};
export default connect(mapStateToProps, { loadServiceAction })(ModulesSection);

// export default connect(mapStateToProps)(withTranslation("common")(Modules));
