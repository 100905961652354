import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import {Grid} from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import MobileMenu from "../MobileMenu";
import Hidden from "@material-ui/core/Hidden";
import Logo from "../Logo";
import {useTranslation} from 'react-i18next';
import Cookies from 'js-cookie'
import Sticky from "react-stickynode";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import en from '../../images/icon/flags/en.svg';
import br from '../../images/icon/flags/br.svg';
import LogoScholar from '../../images/logo.png'
import './style.scss';
import ModulesDropdown from "../NavigationBar/ModulesDropdown";
import SolutionsDropdown from "../NavigationBar/SolutionsDropdown";
import AboutUsDropdown from "../NavigationBar/AboutUsDropdown";

const Header = (props) => {
  const [showMobile, setShowMobile] = useState(false);
  const { mClass, nClass, cClass } = props;
  const [isWidth, setIsWidth] = useState(window.innerWidth < 991);
  const classes = isWidth ? "" : "mega_menu mega_menu_two";
    let pathName = window.location.pathname;
  let modulePath = pathName.includes("Products") ? true : false;
  let solutionPath = pathName.includes("Solutions") ? true : false;
  let aboutPath = pathName.includes("About") ? true : false;

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const iswidth = window.innerWidth < 991;
        if (iswidth !== isWidth) setIsWidth(iswidth);
      },
      []
    );
  }, [isWidth]);

    useEffect(() => { 

        let cookie = Cookies.get('lang');
        if (!cookie) {
            cookie = 'en';
            Cookies.set('lang', cookie);
            setLang(cookie);
        } else {
            setLang(Cookies.get('lang'));
        }

    }, []);
    const { i18n} = useTranslation();

    // function handleClick(lang) {
    //     i18n.changeLanguage(lang);
    //     i18n.changeLanguage(lang);
    // }

    const [lang, setLang] = React.useState('en');
    const handleChange = event => {
        i18n.changeLanguage(event.target.value);
        Cookies.set('lang', event.target.value);
        setLang(Cookies.get('lang'));
    };

  return (
    <Sticky top={0} innerZ={9999}  activeClass="navbar_fixed">
      <Grid
        // className={`header ${
        //   !props.fixHeader ? "fixedHeader header_area navbar_new" : "N

        // }`}
        className={`header fixedHeader header_area navbar_new`}
      >
        {" "} 
        <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
          <Grid className={`container ${cClass}`} container alignItems="center">
            <Grid item xs={6} lg={2}>
              <Logo
                // logo={props.header.logo ? props.header.logo : logo}
                logo={LogoScholar}
                alt="Logo"
                link="/home"
                isHeaderFix={!props.fixHeader}
              />
            </Grid>
            <Hidden mdDown>
              <Grid item xs={12} md={7}>
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="menu_toggle">
                    <span className="hamburger">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                    <span className="hamburger-cross">
                      <span></span>
                      <span></span>
                    </span>
                  </span>
                </button>

                <Grid className="mainMenu">
                  <ul className={`navbar-nav menu ml-auto mr-auto ${nClass}`}>
                    <li className="nav-item">
                      <Link
                        to="/"
                        title="Home"
                        className={`nav-link`}
                        role="button"
                        aria-haspopup="false"
                        aria-expanded="false"
                      >
                        Home
                      </Link>
                    </li>

                    <li className={`nav-item dropdown submenu ${classes}`}>
                      <Link
                        to="#"
                        title="Solutions"
                        className={`dropdown-toggle nav-link ${
                          solutionPath ? "active" : ""
                        }`}
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Solutions
                      </Link>

                      <SolutionsDropdown />
                    </li>

                    <li className={`nav-item dropdown submenu ${classes}`}>
                      <Link
                        to="#"
                        title="Modules"
                        className={`dropdown-toggle nav-link ${
                          modulePath ? "active" : ""
                        }`}
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Features
                      </Link>

                      <ModulesDropdown />
                    </li>

                    <li className={`nav-item dropdown submenu ${classes}`}>
                      <Link
                        to="#"
                        title="About"
                        className={`dropdown-toggle nav-link ${
                          aboutPath ? "active" : ""
                        }`}
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        About Us
                      </Link>
                      <AboutUsDropdown />
                    </li>
                    <li className="nav-item">
                      <Link title="Plans" className="nav-link" to="/plans">
                        Plans
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link title="Blog" className="nav-link" to="/blog">
                        Blog
                      </Link>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Hidden>

            <Hidden xsDown>
              <Grid
                item
                xs={3}
                lg={3}
                sm={4}
                container
                alignItems="center"
                className="justify-md-flex-space-between"
                justifyContent="flex-end"
              >
                <Button
                  component={Link}
                  to="/contact-us"
                  className="cBtn cBtnOutline cBtnUppercase"
                >
                  Contact Us
                </Button>
                <FormControl className="languageSelector">
                  <Select
                    value={lang}
                    onChange={handleChange}
                    displayEmpty
                    className="languageSelect"
                  >
                    <MenuItem value="en">
                      <img src={en} alt="language" />
                      en
                    </MenuItem>
                    <MenuItem value="br">
                      <img src={br} alt="language" />
                      br
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Hidden>

            <Hidden lgUp>
              <Grid item xs={6} sm={2} className="text-right">
                <Grid
                  className={`humbergur ${showMobile ? "show" : ""}`}
                  onClick={() => setShowMobile(!showMobile)}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <MobileMenu showMobile={showMobile} setShowMobile={setShowMobile} />
        </nav>
      </Grid>
    </Sticky>
  );
};

const mapStateToProps = state => {
    return {
        header: state.header,
        menus: state.header.menu_list,
    }
};

export default connect(mapStateToProps)(withRouter(Header));