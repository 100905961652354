import React,{Suspense} from 'react';
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid"; 
import "./style.scss";
import CaseData from "../CaseData/Admissions";
import FeaturesSection from "../../components/FeaturesSection"
import FrequentlyAskedQsn from "../../components/FrequentlyAskedQsn"
import TopSectionTitle from '../TopSectionTitle';
import InnerCaseLeft from "../../components/InnerCaseLeft"
import InnerCaseRight from "../../components/InnerCaseRight"
//  const InnerCaseLeft = React.lazy(() =>
//    import("../../components/InnerCaseLeft")
//  );
//  const InnerCaseRight = React.lazy(() =>
//    import("../../components/InnerCaseRight")
//  );
function Admission() {
  return (
    <>
        <Suspense fallback={<div>Loading...</div>}>
          {/* // <Grid className="" container spacing={4}>
Name      //   <Grid item xs={12} md={8}> */}
          <Grid className="textContent"></Grid>
          <div className="content-section-pratner">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <TopSectionTitle
                    subtitle=" What is Admission Management?"
                    body="The ultimate aim of any Educational institution is to
                    increase the enrollments as it is directly proportional to
                    the business of the institution. A lot of times schools get
                    the enquiries but they find it difficult to convert them as
                    with the dynamic market, the needs of parents & students are
                    also changing. These are the critical areas that need to be
                    addressed in the Admission process of any school. That is
                    where the relevance of admission management software comes
                    into picture"
                  />
        
                </div>
              </div>
            </div>
            <div className="clear"></div>
          </div>
          <div className="content-section-pratner">
            <h2 className="wow fadeInUp">Overview</h2>
          </div>
          {CaseData &&
            CaseData.map((integrations) => {
              return (
                <InnerCaseLeft
                  img={integrations?.image1}
                  title={integrations.title1}
                  listItem={[
                    integrations.Case1point1 && {
                      item: integrations.Case1point1,
                    },
                    integrations.Case1point2 && {
                      item: integrations.Case1point2,
                    },
                    integrations.Case1point3 && {
                      item: integrations.Case1point3,
                    },
                  ]}
                />
              );
            })}
          ;
          {CaseData &&
            CaseData.map((integrations1) => {
              return (
                <InnerCaseRight
                  title={integrations1.title2}
                  listItem={[
                    integrations1.Case2point1 && {
                      item: integrations1.Case2point1,
                    },
                    integrations1.Case2point2 && {
                      item: integrations1.Case2point2,
                    },
                    integrations1.Case2point3 && {
                      item: integrations1.Case2point3,
                    },
                  ]}
                  img={integrations1?.image2}
                />
              );
            })}
          ;
          {CaseData &&
            CaseData.map((integrations) => {
              return (
                <InnerCaseLeft
                  
                  title={integrations.title3}
                  listItem={[
                    integrations.Case3point1 && {
                      item: integrations.Case3point1,
                    },
                    integrations.Case3point2 && {
                      item: integrations.Case3point2,
                    },
                    integrations.Case3point3 && {
                      item: integrations.Case3point3,
                    },
                  ]}
                  img={integrations?.image3}
                />
              );
            })}
          ;
          {CaseData &&
            CaseData.map((integrations1) => {
              return (
                <InnerCaseRight
                  title={integrations1.title4}
                  listItem={[
                    integrations1.Case4point1 && {
                      item: integrations1.Case4point1,
                    },
                    integrations1.Case4point2 && {
                      item: integrations1.Case4point2,
                    },
                    integrations1.Case4point3 && {
                      item: integrations1.Case4point3,
                    },
                  ]}
                  img={integrations1?.image4}
                />
              );
            })}
          <div
            className="w-full flex justify-center lg:justify-start mb-6 mt-6"
            style={{ marginLeft: "40%", marginBottom: "50px" }}
          >
            {/* <div
              className=" tm-bgcr-bl-2 tm-cr-wh-1 w-52 rounded-xl cursor-pointer px-4 py-4 lg:px-6 lg:py-4 text-center"
              id="sp-ips-banner"
              onclick="triggerFormModal('True')"
            > */}
            <Link to="/contact-us" className="cBtn">
              Get Started
            </Link>
          </div>
          <FeaturesSection />
          <FrequentlyAskedQsn />
          {/* </Grid>
      </Grid> */}
        </Suspense>
    </>
  );
}

export default Admission;