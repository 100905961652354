import React, { Fragment } from "react";

import BusTracking from "../../components/BusTracking";
import Footer from "../../components/Footer";
import Banner from "../../components/Banner";

import Image from "../../images/banner.png";

const Gallery = () => {

  return (
    <Fragment>
      <Banner
        subtitle="GPS Bus Tracking System"
        title="Transport Management System"
        pagedescription="Track, manage and convert admission enquiries easily and
                effectively. Set up admission enquiry form and collect enquiries
                from any sources."
        bannerImg={Image}
      />

      <BusTracking className="hasMb" />
      <Footer />
    </Fragment>
  );
};



export default Gallery;
