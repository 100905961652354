import React from "react";
import "./style.scss";
import { NavLink } from "react-router-dom";
const baseUri = process.env.PUBLIC_URL;

function ModulesDropdown({ dWidth }) {
  return (
    <div className={`row mega_menu_inner d-flex about_dropdown ${dWidth}`}>
      <div
        className="maincontainersolution"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <div className="innerfirstsolutioncontainer innersolutioncontainer">
          <div className="namecontainerr">
            <NavLink
              exact
              title="AdmissionManagementSystem"
              className="nav-link"
              to="/admissions"
            >
              <div>
                <img
                  src={baseUri + "/images/contract.png"}
                  alt="simg"
                  style={{ height: "40px", width: "40px" }}
                />
              </div>
              <div>
                <h6>Admission Management System</h6>
                <p>Admission bsfksdk anagement hend mission nagement </p>
              </div>
            </NavLink>
          </div>

          <div className="namecontainerr">
            <NavLink
              exact
              title="AssessmentManagementSystem"
              className="nav-link"
              to="/assessment"
            >
              <div>
                <img
                  src={baseUri + "/images/behavior.png"}
                  alt="simg"
                  style={{ height: "40px", width: "40px" }}
                />
              </div>
              <div>
                <h6> Assessment Management System</h6>
                <p>Admission bsfksdk anagement hend mission nagement </p>
              </div>
            </NavLink>
          </div>

          <div className="namecontainerr">
            <NavLink exact title="Reports" className="nav-link" to="/reports">
              <div>
                <img
                  src={baseUri + "/images/report.png"}
                  alt="simg"
                  style={{ height: "40px", width: "40px" }}
                />
              </div>
              <div>
                <h6>Reports</h6>
                <p>Admission bsfksdk anagement hend mission nagement </p>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="innersecondsolutioncontainer innersolutioncontainer">
          <div className="namecontainerr">
            <NavLink
              exact
              title="LearningManagementSystem"
              className="nav-link"
              to="/lms"
            >
              <div>
                <img
                  src={baseUri + "/images/learning.png"}
                  alt="simg"
                  style={{ height: "40px", width: "40px" }}
                />
              </div>
              <div>
                <h6>Learning Management System</h6>
                <p>Admission bsfksdk anagement hend mission nagement </p>
              </div>
            </NavLink>
          </div>

          <div className="namecontainerr">
            <NavLink
              exact
              title="FeesManagementSystem"
              className="nav-link"
              to="/fees-management"
            >
              <div>
                <img
                  src={baseUri + "/images/fees.png"}
                  alt="simg"
                  style={{ height: "40px", width: "40px" }}
                />
              </div>
              <div>
                <h6>Fees Management System</h6>
                <p>Admission bsfksdk anagement hend mission nagement </p>
              </div>
            </NavLink>
          </div>
          <div className="namecontainerr">
            <NavLink
              exact
              title="ExamPlanner"
              className="nav-link"
              to="/exam-planner"
            >
              <div>
                <img
                  src={baseUri + "/images/exam.png"}
                  alt="simg"
                  style={{ height: "40px", width: "40px" }}
                />
              </div>
              <div>
                <h6>Exam Planner</h6>
                <p>Admission bsfksdk anagement hend mission nagement </p>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="innerthirdsolutioncontainer innersolutioncontainer">
          <div className="namecontainerr">
            <NavLink
              exact
              title="StudentInformationSystem"
              className="nav-link"
              to="/student-information"
            >
              <div>
                <img
                  src={baseUri + "/images/personal-information.png"}
                  alt="simg"
                  style={{ height: "40px", width: "40px" }}
                />
              </div>
              <div>
                <h6>Student Information System</h6>
                <p>Admission bsfksdk anagement hend mission nagement </p>
              </div>
            </NavLink>
          </div>

          <div className="namecontainerr">
            <NavLink
              exact
              title="StudentTrackingSystem"
              className="nav-link"
              to="/student-tracking"
            >
              <div>
                <img
                  src={baseUri + "/images/tracking-app.png"}
                  alt="simg"
                  style={{ height: "40px", width: "40px" }}
                />
              </div>
              <div>
                <h6>Student Tracking System</h6>
                <p>Admission bsfksdk anagement hend mission nagement </p>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModulesDropdown;
