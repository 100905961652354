import React, { Fragment} from "react";

import SmartAttendance from "../../components/SmartAttendance";
import Footer from "../../components/Footer";
import Banner from "../../components/Banner";

import Image from "../../images/Smart-Attendance-Management.png";

const Gallery = () => {

  return (
    <Fragment>
      <Banner
        subtitle="Attendance Management"
        title=" Smart Attendance Management System"
        pagedescription="Track, manage and convert admission enquiries easily and
                effectively. Set up admission enquiry form and collect enquiries
                from any sources."
        bannerImg={Image}
      />
      <SmartAttendance className="hasMb" />
      <Footer />
    </Fragment>
  );
};


export default Gallery;
