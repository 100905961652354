import React from "react";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
// import Hidden from "@material-ui/core/Hidden";

// import breadcrumbBg from "../../images/breadcrumb-bg.png";
// import brdShape from "../../images/brdshape.png";
import "./style.scss"



const Banner = ({
  subtitle,title,
  pagedescription,
  bannerImg,
  className = "",
}) => {
  return (
    <Grid className="">
      <Grid container className="container-fluid banner">
        <div className="row h-100">
          <div className="col-lg-6 p-5">
            <div className={`center_content_100 ${className}`}>
              <strong>{subtitle}</strong>
              <h1>{title}</h1>
              <p>{pagedescription}</p>
              <div className="d-flex align-items-center justify-content-start w-100">
                <Link to="/contact-us">
                  {" "}
                  <button className="cBtn">Get Demo</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <img src={bannerImg} alt={title} className="mw-50"></img>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Banner;
