import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import "./style.scss";
import BenefitsSection from "../BenefitsSection";
import FrequentlyAskedQsn from "../FrequentlyAskedQsn";
import { loadServiceAction } from "../../store/actions/action";
import { MdLaptopMac } from "react-icons/md";
import { GiBookshelf } from "react-icons/gi";
import { FaBlenderPhone } from "react-icons/fa";
import TopSectionTitle from "../TopSectionTitle";
import data from "../BenefitsSection/Softdata";

const overviews = [
  {
    title: "State-of-the-art ERP System",
    description:
      "  Equip the school with the best ERP system that’s aimed  at helping them digitize & automate their time-consuming day-to-day operations",
    icon: <FaBlenderPhone />,
  },
  {
    title: "Effective LMS Solution",
    description:
      " Transform the classroom with easier assignment & evaluation of homework and tests, sharing of study materials, and lesson plan tracking and more!",
    icon: <GiBookshelf />,
  },
  {
    title: "Premium Quality Digital Content",
    description:
      "Use our Premium Digital Content to make classes interactive, immersive, and engaging.It has become a trend these days as students find it more convenient to learn .",
    icon: <MdLaptopMac />,
  },
];
const SchoolSoftware = () => {

 
  return (
    <>
      {/* // <Grid className="" container spacing={4}>
    //   <Grid item xs={12} md={8}> */}
      <Grid className="textContent"></Grid>
      <div className="content-section-pratner">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <TopSectionTitle
                subtitle=" What is the School Management Software?"
                title=""
                body="  Simplify the school’s administrative process through
                digitization and automation - use the best School Management
                Software in the market and experience the simplicity and
                efficiency. "
              />
           
            </div>
          </div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="content-section-pratner">
        <h2 className="wow fadeInUp">Overview</h2>
      </div>
      <div className="modulesWrapperr">
        <div className="Container">
          <div id="modules" className="modules-section page-section">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 modules-sec">
                  <div id="mostmodul">
                    <div id="mostmodul">
                      {overviews.map((overview, index) => (
                        <div key={index} className="modulbox">
                          <a
                            className="wow pulse"
                            data-toggle="modal"
                            data-target="#myModal101"
                            href="/"
                          >
                            <div></div>

                            <i className="icon-book-open icons iconss">
                              {overview.icon}
                            </i>
                            <h4>{overview.title}</h4>
                            <span>{overview.description}</span>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BenefitsSection data={data} />
      <FrequentlyAskedQsn />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    service: state.service,
  };
};
export default connect(mapStateToProps, { loadServiceAction })(SchoolSoftware);
