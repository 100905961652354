import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import CaseData from "../CaseData/Assessment";
import FeaturesSection from "../FeaturesSection";
import TopSectionTitle from "../TopSectionTitle";

import InnerCaseLeft from "../../components/InnerCaseLeft"
import InnerCaseRight from "../../components/InnerCaseRight"
//  const InnerCaseLeft = React.lazy(() =>
//    import("../../components/InnerCaseLeft")
//  );
//  const InnerCaseRight = React.lazy(() =>
//    import("../../components/InnerCaseRight")
//  );
const Assessment = () => {
  return (
    <>
      <Suspense fallback={null}>
        {/* // <Grid className="" container spacing={4}>
    //   <Grid item xs={12} md={8}> */}
        <Grid className="textContent"></Grid>
        <div className="content-section-pratner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <TopSectionTitle
                  subtitle=" Make the best of every assessment"
                  body="    Get real-time detailed insights into student performance and
                  make informed decisions using tests and homework. An
                  Assessment management system is an important part of the
                  learning process as it helps in showcasing the performance
                  journey of the students and teachers can also figure out the
                  areas of improvement of the students as it provides all the
                  reports and analytics."
                />
          
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="content-section-pratner">
          <h2 className="wow fadeInUp">Overview</h2>
        </div>
        {CaseData &&
          CaseData.map((integrations) => {
            return (
              <InnerCaseLeft
                img={integrations?.image1}
                title={integrations.title1}
                listItem={[
                  integrations.Case1point1 && {
                    item: integrations.Case1point1,
                  },
                  integrations.Case1point2 && {
                    item: integrations.Case1point2,
                  },
                  integrations.Case1point3 && {
                    item: integrations.Case1point3,
                  },
                ]}
              />
            );
          })}
        ;
        {CaseData &&
          CaseData.map((integrations1) => {
            return (
              <InnerCaseRight
                title={integrations1.title2}
                listItem={[
                  integrations1.Case2point1 && {
                    item: integrations1.Case2point1,
                  },
                  integrations1.Case2point2 && {
                    item: integrations1.Case2point2,
                  },
                  integrations1.Case2point3 && {
                    item: integrations1.Case2point3,
                  },
                ]}
                img={integrations1?.image2}
              />
            );
          })}
        ;
        {CaseData &&
          CaseData.map((integrations) => {
            return (
              <InnerCaseLeft
                img={integrations?.image3}
                title={integrations.title3}
                listItem={[
                  integrations.Case3point1 && {
                    item: integrations.Case3point1,
                  },
                  integrations.Case3point2 && {
                    item: integrations.Case3point2,
                  },
                  integrations.Case3point3 && {
                    item: integrations.Case3point3,
                  },
                ]}
              />
            );
          })}
        ;
        {CaseData &&
          CaseData.map((integrations1) => {
            return (
              <InnerCaseRight
                title={integrations1.title4}
                listItem={[
                  integrations1.Case4point1 && {
                    item: integrations1.Case4point1,
                  },
                  integrations1.Case4point2 && {
                    item: integrations1.Case4point2,
                  },
                  integrations1.Case4point3 && {
                    item: integrations1.Case4point3,
                  },
                ]}
                img={integrations1?.image4}
              />
            );
          })}
        <div
          className="w-full flex justify-center lg:justify-start mb-6 mt-6"
          style={{ marginLeft: "40%", marginBottom: "50px" }}
        >
          <Link to="/contact-us" className="cBtn">
            Get Started
          </Link>
        </div>
        <FeaturesSection />
      </Suspense>
    </>
  );
};

export default Assessment;
