import React from "react";
import {
  MdOutlinePhonelink,
  MdFeedback,
  MdInstallMobile,
  MdUpdate,
  MdPayment,
  MdOutlineScreenSearchDesktop,
  MdOutlinePayments,
  MdSupport,
  MdSecurity,
  MdPriceCheck,
  MdContentCopy,
  MdSchool
} from "react-icons/md";
import {
  FaLaptopCode,
  FaSuitcase,
  FaFileExport,
  FaBookReader,
  FaChartLine,
  FaNewspaper,
  FaUsers,
} from "react-icons/fa";
import {GrIntegration,GrUserSettings,GrDatabase,GrTemplate,GrDocumentPerformance,GrPlan} from "react-icons/gr";
import {RiTeamFill,RiParentFill} from "react-icons/ri";
import {GiBookshelf,GiVideoConference,GiTimeBomb} from "react-icons/gi";
import {IoNewspaperOutline } from "react-icons/io5";
import {LuSmartphoneNfc} from "react-icons/lu";
import {
  TbReportSearch,
  TbBookDownload,
  TbBellRingingFilled,
} from "react-icons/tb";
import {BiVideoRecording,BiSupport} from "react-icons/bi";
import {TiSocialYoutube} from "react-icons/ti";
import {SlCalender} from "react-icons/sl";
import {SiHomeassistantcommunitystore} from "react-icons/si";
import { AiOutlineSafetyCertificate, AiOutlineWechat } from "react-icons/ai";

const Data = [
  {
    icon: <MdOutlinePhonelink />,
    title: "Integrated School Platform (ISP)",
    desc: "ISP with advanced administrator tools & learning management system to manage all school activities and provide an enriched learning experience.",
  },
  {
    icon: <MdInstallMobile />,
    title: "Mobile First",
    desc: "Mobile first platform to give real-time on the go access to all the stakeholders including Admin, Teacher, Student and Parent.",
  },
  {
    icon: <FaLaptopCode />,
    title: "Modern UI",
    desc: "A modern user interface for easy access and navigation for all the stakeholders.",
  },
  {
    icon: <GrUserSettings />,
    title: "Easy set-up",
    desc: "Designed to set up a new school account in few easy steps.",
  },
  {
    icon: <BiSupport />,
    title: "Dedicated support",
    desc: "A dedicated & trained support team to make sure you get a smooth onboarding experience and support throughout the year.",
  },
  {
    icon: <MdUpdate />,
    title: "New updates",
    desc: "Regular updates & addition of new features in the existing features to make the lives of school stakeholders easy.",
  },
  {
    icon: <RiTeamFill />,
    title: "Strong Team",
    desc: "Company with 500+ employees with a focus on expanding the potential of education through technology.",
  },
  {
    icon: <GiBookshelf />,
    title: "Knowledge book",
    desc: "An upto date knowledge book for self understanding on any features or solutions to use the system effectively.",
  },
  {
    icon: <BiSupport />,
    title: "Support Ticket",
    desc: "Integrated support ticket tool in school admin dashboard with access to ticket resolution journey.",
  },
  {
    icon: <GrIntegration />,
    title: "Hardware integrations",
    desc: "Attendance & GPS bus tracking hardware integrations for better operations.",
  },
  {
    icon: <GiVideoConference />,
    title: "Own Video conferencing",
    desc: "Proprietary video conferencing technology to enable unlimited live classes with unique features & less data consumption.",
  },
  {
    icon: <IoNewspaperOutline />,
    title: "Dynamic online tests",
    desc: "A smart solution to conduct online tests with a variety of test setting options and performance statistics.",
  },
  {
    icon: <TbReportSearch />,
    title: "Strong reports",
    desc: "Dynamic reports across the system to enable better monitoring and operations of school.",
  },
  {
    icon: <LuSmartphoneNfc />,
    title: "Smart communication",
    desc: "Communication through announcements, polls, feedback, SMS and push notifications to keep all the stakeholders updated",
  },
  {
    icon: <FaUsers />,
    title: "20 Million+ Users",
    desc: "A large pool of 20M+ platform users with 15M+ App downloads. A system which can support a very large user base with smooth operations",
  },
  {
    icon: <MdOutlineScreenSearchDesktop />,
    title: "Top rated",
    desc: "140K+ reviews on Google Play Store. Top positions on major software discovery platforms. Trusted by a wide user-base.",
  },
  {
    icon: <TbBookDownload />,
    title: "Complete admission automation",
    desc: "A solution to drive paperless admission process with real-time data collection backed by strong inquiry CRM to smoothen admission process.",
  },
  {
    icon: <MdOutlinePayments />,
    title: "Hassle-free fee collection",
    desc: "Easy online-offline fee collection & management with mobile compatibility.",
  },
  {
    icon: <FaSuitcase />,
    title: "Operations management suit",
    desc: "A strong suit of features to manage school operations like Transport, HRMS, Library, etc. effectively.",
  },
  {
    icon: <TbReportSearch />,
    title: "Academic reports",
    desc: "Detailed reports on academic activities and progress to help management stay updated on academic achievement and make better decisions.",
  },
  {
    icon: <BiVideoRecording />,
    title: "Unlimited recordings",
    desc: "Live class with unlimited recording option to help teachers build recorded library of all the online classes. Provide easy access to students for revisions or missed classes.",
  },
  {
    icon: <FaBookReader />,
    title: "Learning content sharing",
    desc: "Share learning content in formats like PDFs, videos, and documents in organized folder structure for anytime-anywhere access.",
  },
  {
    icon: <GrUserSettings />,
    title: "Roles & permissions",
    desc: "Create custom roles for every staff member based on school needs and define the access permission.",
  },
  {
    icon: <FaChartLine />,
    title: "Multi-branch dashboard",
    desc: "Manage multiple branches of your school chain through a single dashboard with central data visualization.",
  },
  {
    icon: <GiTimeBomb />,
    title: "99.9% Uptime",
    desc: "Highly reliable system with almost 100% uptime for smooth experience. Strong server setup and scalability to exceed reliability expectations of schools.",
  },
  {
    icon: <MdSupport />,
    title: "Migration support",
    desc: "Dedicated guidance and support when moving from an existing legacy system to the ScholarErp Integrated School Platform.",
  },
  {
    icon: <GrDatabase />,
    title: "Store historic data",
    desc: "Store previous years data based on school needs to have a wider digital data availability.",
  },
  {
    icon: <MdFeedback />,
    title: "Customer feedback",
    desc: "ScholarErp takes constructive customer feedback with utmost sincerity to improve overall product and service ecosystem to support our wide user base.",
  },
  {
    icon: <MdSecurity />,
    title: "Security",
    desc: "Data security is non-negotiable. ScholarErp has a dedicated security team for regular monitoring, audits, and updates required for providing a highly secure platform to our customers.",
  },
  {
    icon: <GrTemplate />,
    title: "Ready to use templates",
    desc: "A lot of dynamic and ready-to-use templates to be accessed by school administrators with a single click. Ready-to-use templates save a lot of time .",
  },
  {
    icon: <MdPriceCheck />,
    title: "Transparent pricing",
    desc: "A very clear and easy-to-understand pricing structure. No hidden charges to make sure it supports the school's budget and planning.",
  },
  {
    icon: <TiSocialYoutube />,
    title: "Strong Social presence",
    desc: "586K+ YouTube subscribers, 600+ Videos. Continuous investment on social media presence to keep the school community updated about our developments.",
  },
  {
    icon: <FaNewspaper />,
    title: "Custom report cards",
    desc: "A strong progress report card designing tool to meet various formats of report card followed by schools. Regular improvements to support more formats to cover wider requirements.",
  },
  {
    icon: <TbBellRingingFilled />,
    title: "Auto reminder rules",
    desc: "Smart auto reminder rules setting for birthdays, fee due, and attendance notifications.",
  },
  {
    icon: <AiOutlineWechat />,
    title: "Chat module",
    desc: "A strong chat module with flexible settings to meet the school's security requirements and facilitate better communication.",
  },
  {
    icon: <GrDocumentPerformance />,
    title: "Classroom performance",
    desc: "A uniquely designed dashboard for teachers and school admins to monitor classroom performance and take necessary improvement actions.",
  },
  {
    icon: <GrPlan />,
    title: "Custom lesson plan",
    desc: "Design your lesson plan structure and monitor progress. Share lesson linked content and learning resources for students' convenience.",
  },
  {
    icon: <SlCalender />,
    title: "Smart calendar",
    desc: "An App based calendar view to provide central access of all important events, activities, and action items for a productive and quality experience",
  },
  {
    icon: <MdContentCopy />,
    title: "Premium Content",
    desc: "Premium learning content resources for after-school learning support. Currently available for Indian schools only.",
  },
  {
    icon: <SiHomeassistantcommunitystore />,
    title: "Smart homework",
    desc: "Dynamic homework module to keep students engaged in after-school homework activities. Options to submit digital homework or participate in MCQ tests assigned as homework.",
  },
  {
    icon: <AiOutlineSafetyCertificate />,
    title: "ISO Certified",
    desc: "ScholarErp is ISO 27001 certified, world's best-known standard for information security management systems (ISMS)",
  },
  {
    icon: <MdPayment />,
    title: "Multiple Payment Gateways",
    desc: "A range of payment gateway integrations to support digital fee collection. Continuous addition to support more payment gateways for flexibility in fee collection.",
  },
  {
    icon: <FaFileExport />,
    title: "Data export",
    desc: "Flexibility to export data and reports in excel for offline usage.",
  },
  {
    icon: <RiParentFill />,
    title: "Students & parents",
    desc: "Every requirement for students & parents is accessible on Mobile App. Information, data, and learning on the go through Mobile App.",
  },
  {
    icon: <MdSchool />,
    title: "School centric solutions",
    desc: "ScholarErp is exclusively focused to build tech-enabled solutions for schools. A strong team with a singular vision to help schools go digital.",
  },
];

export default Data;
