import React, { Suspense } from "react";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import CaseData from "../CaseData/Selfmanaged";
import RightCaseData from "../RightCaseData";
import { IoLibraryOutline } from "react-icons/io5";
import InnerCaseLeft from "../../components/InnerCaseLeft"
import InnerCaseRight from "../../components/InnerCaseRight"


const baseUri = process.env.PUBLIC_URL;
const SchoolSoftware = () => {
  
  return (
    <>
      <Suspense fallback={null}>
        {/* // <Grid className="" container spacing={4}>
         //   <Grid item xs={12} md={8}> */}
        <Grid className="textContent"></Grid>
        <div className="content-section-pratner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="wow fadeInUp">Student Information System</h2>
                <div className="content-partone wow fadeInUp">
                  Education and 'Edutech' are consistently growing as potential
                  sectors. Intervention of technology in education has never
                  witnessed better times than present. More and more schools,
                  colleges and institutes are looking for reliable, cost
                  effective yet comprehensive and value-added ERP solutions to
                  manage things in an improved way thereby connecting the key
                  stakeholders on a common platform.{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="content-section-pratner">
          <h2 className="wow fadeInUp">Overview</h2>
        </div>
        <div className="modulesWrapperr">
          <div className="Container">
            <div id="modules" className="modules-section page-section">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 modules-sec">
                    <div id="mostmodul">
                      <div className="modulbox">
                        <a
                          className="wow pulse"
                          data-toggle="modal"
                          data-target="#myModal101"
                          href="/"
                        >
                          <div></div>

                          <i className="icon-book-open icons iconss">
                            <IoLibraryOutline />
                          </i>
                          <h4>Easy Categorization</h4>
                          <span>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry
                          </span>
                        </a>
                      </div>
                      <div className="modulbox">
                        <a
                          className="wow pulse"
                          data-toggle="modal"
                          data-target="#myModal101"
                          href="/"
                        >
                          <div></div>

                          <i className="icon-book-open icons">
                            <IoLibraryOutline />
                          </i>
                          <h4>Customized Fee Reports</h4>
                          <span>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry
                          </span>
                        </a>
                      </div>
                      <div className="modulbox">
                        <a
                          className="wow pulse"
                          data-toggle="modal"
                          data-target="#myModal101"
                          href="/"
                        >
                          <div></div>

                          <i className="icon-book-open icons">
                            <IoLibraryOutline />
                          </i>
                          <h4>Timely Reminders</h4>
                          <span>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {CaseData &&
          CaseData.map((integrations) => {
            return (
              <InnerCaseLeft
                img={integrations?.image1}
                title={integrations.title1}
                listItem={[
                  integrations.Case1point1 && {
                    item: integrations.Case1point1,
                  },
                  integrations.Case1point2 && {
                    item: integrations.Case1point2,
                  },
                  integrations.Case1point3 && {
                    item: integrations.Case1point3,
                  },
                  integrations.Case1point4 && {
                    item: integrations.Case1point4,
                  },
                  integrations.Case1point5 && {
                    item: integrations.Case1point5,
                  },
                ]}
              />
            );
          })}
        ;
        <section className="sec_pad">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <div className="testimonial_title">
                  <div
                    className="seo_sec_title wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    {/* <h2 className="usecase_title">Title2</h2> */}
                    <h5 className="usecase_main__title">
                      Effortless Management
                    </h5>
                  </div>
                  <div className="row pt-3">
                    <ul className="list-channel">
                      <li>
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                        Tremendous market size
                      </li>
                      <li data-wow-delay="0.2s" className=" wow fadeInUp">
                        {" "}
                        <i className="fa fa-check" aria-hidden="true"></i>Growth
                        and future potential{" "}
                      </li>
                      <li data-wow-delay="0.3s" className=" wow fadeInUp">
                        <i className="fa fa-check" aria-hidden="true"></i>
                        Increasing trend towards technology adoption
                      </li>
                      <li data-wow-delay="0.4s" className=" wow fadeInUp">
                        <i className="fa fa-check" aria-hidden="true"></i>
                        Minimal investment
                      </li>
                      <li data-wow-delay="0.5s" className=" wow fadeInUp">
                        <i className="fa fa-check" aria-hidden="true"></i>
                        Assured revenue stream
                      </li>
                      <li data-wow-delay="0.6s" className=" wow fadeInUp">
                        <i className="fa fa-check" aria-hidden="true"></i>
                        Consistent source of recurring income{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ai_img_outer">
                <div className="d-flex align-items-center ai_services">
                  <img src={baseUri + "/pic3.png"} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {CaseData &&
          CaseData.map((integrations) => {
            return (
              <InnerCaseLeft
                img={integrations?.image1}
                title={integrations.title1}
                listItem={[
                  integrations.Case1point1 && {
                    item: integrations.Case1point1,
                  },
                  integrations.Case1point2 && {
                    item: integrations.Case1point2,
                  },
                  integrations.Case1point3 && {
                    item: integrations.Case1point3,
                  },
                  integrations.Case1point4 && {
                    item: integrations.Case1point4,
                  },
                  integrations.Case1point5 && {
                    item: integrations.Case1point5,
                  },
                ]}
              />
            );
          })}
        {RightCaseData &&
          RightCaseData.map((integrations1) => {
            return (
              <InnerCaseRight
                title={integrations1.title1}
                listItem={[
                  integrations1.Case1point1 && {
                    item: integrations1.Case1point1,
                  },
                  integrations1.Case1point2 && {
                    item: integrations1.Case1point2,
                  },
                  integrations1.Case1point3 && {
                    item: integrations1.Case1point3,
                  },
                  integrations1.Case1point4 && {
                    item: integrations1.Case1point4,
                  },
                  integrations1.Case1point5 && {
                    item: integrations1.Case1point5,
                  },
                ]}
                img={integrations1?.image1}
              />
            );
          })}
        ;
      </Suspense>
    </>
  );

};

export default SchoolSoftware;
