import React, { Fragment } from "react";

import Plans from "../../components/Plans";
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";

const Gallery = () => {
  return (
    <Fragment>
      <BreadCrumb pagename="Our Plans" />

      <Plans className="hasMb" />
      <Footer />
    </Fragment>
  );
};

export default Gallery;
