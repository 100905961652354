import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";
import HireUs from "../../components/HireUs";

import Grid from "@material-ui/core/Grid";

import {loadTeamAction} from "../../store/actions/action";

import './style.scss';
const baseUri = process.env.PUBLIC_URL;
const TeamPage = (props) => {
    // useEffect(() => {
    //     props.loadTeamAction()
    // },[]);

    const { team_banner_title} = props.team;
    return (
      <Fragment>
        <BreadCrumb pagename="Our Team" title={team_banner_title} />

        <Grid className="allTeam">
          <Grid container className="container">
            {/* {team_list !== undefined ? (
              team_list.map((item) => {
                return (
                  <Grid key={item.id} item sm={6} md={4} xs={12}>
                    <Grid className="expertTeam mb-30">
                      <Grid className="thumb">
                        <img src={item.image} alt="" />
                      </Grid>
                      <Grid className="content">
                        <h4>{item.name}</h4>
                        <p>{item.designation}</p>
                      </Grid>
                    </Grid>
                  </Grid>   
                );
              })
            ) : ( */}
            <Grid className="expert" item sm={6} md={9} xs={12}>
              <Grid className="expertTeam mb-30">
                <Grid className="thumb">
                  <img src={baseUri + "/images/team1.png"} alt="" />
                </Grid>
                <Grid className="content">
                  <h4>Jigar Shah</h4>
                  <p>CEO & Managing Director</p>
                </Grid>
              </Grid>
              <Grid className="expertTeam mb-30">
                <Grid className="thumb">
                  <img src={baseUri + "/images/team3.png"} alt="" />
                </Grid>
                <Grid className="content">
                  <h4>Vishal Dave</h4>
                  <p>Marketing Director</p>
                </Grid>
              </Grid>
              <Grid className="expertTeam mb-30">
                <Grid className="thumb">
                  <img src={baseUri + "/images/team2.png"} alt="" />
                </Grid>
                <Grid className="content">
                  <h4>Vini Bhavsar</h4>
                  <p>HR Manager</p>
                </Grid>
              </Grid>
        
            </Grid>
            {/* )} */}
          </Grid>
        </Grid>

        <HireUs />
        <Footer />
      </Fragment>
    );
};

const mapStateToProps = state => {
    return {
        team: state.team
    }
};

export default connect(mapStateToProps, {loadTeamAction})(TeamPage);