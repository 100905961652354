import React from "react";
import "./style.scss";
import Data from "../ReasonsWhy/data";


const ReasonsWhy = () => {
  return (
    <div className="modulesWrapperr">
      <div className="Container">
        <div id="modules" className="modules-section page-section">
          <h2 className="wow fadeInUp">50 Reasons Why?</h2>

          <div className="container">
            <div className="row">
              <div className="col-sm-12 modules-sec">
                <div id="mostmodul">
                  {Data &&
                    Data.map((item) => {
                      return (
                        <>
                          <div className="modulbox">
                            <a
                              className="wow pulse"
                              data-toggle="modal"
                              data-target="#myModal101"
                              href="/"
                            >
                              <div></div>

                              <i className="icon-book-open icons iconss">
                                {item.icon}
                              </i>
                              <h4>{item.title}</h4>
                              <span>{item.desc}</span>
                            </a>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReasonsWhy;