import React, { Fragment } from "react";

import LMS from "../../components/LMS";
import Footer from "../../components/Footer";
import Banner from "../../components/Banner";

import Image from "../../images/lms.png";

const Gallery = () => {

  return (
    <Fragment>
      <Banner
        subtitle="Learning Management System"
        title="Transform your teaching & learning infrastructure"
        pagedescription="Track, manage and convert admission enquiries easily and
                effectively. Set up admission enquiry form and collect enquiries
                from any sources."
        bannerImg={Image}
      />

      <LMS className="hasMb" />
      <Footer />
    </Fragment>
  );
};


export default Gallery;
