const baseUri = process.env.PUBLIC_URL;

const CaseData = [
  {
    pageName: "Admissions",
    image1: baseUri + "./images/features/a)Simplified Setup.svg",
    title1: "Easy Digitization",
    Case1point1: "Track students’ admission status anytime anywhere",
    Case1point2:
      "Digitize collection & processing of student records & minimize paperwork",
    Case1point3: "Update, maintain, and store student information securely",
    image2: baseUri + "./images/features/b)Systematic-Lead-Nurturing-.png",
    title2: "Systematic Lead Nurturing",
    Case2point1: " Divide students based on application (offline/online)",
    Case2point2: "Customize lead stages based on the admission stage",
    Case2point3: "Change leads stages for easy categorization & processing",
    image3: baseUri + "./images/features/c)Effortless Progress Tracking.svg",
    title3: "Effortless Progress Tracking",
    Case3point1: " Track progress of leads & take informed action efficiently",
    Case3point2:
      "Identify high potential leads through admin & student activity on the admission management portal",
    Case3point3: "Configure & collect fees at the class level",
    image4: baseUri + "./images/features/d)One-stop-Solution.png",
    title4: "One-stop Solution",
    Case4point1: " Customize your Admission Portal with incredible precision",
    Case4point2: "Resolve student enquiries as quickly as they come up",
    Case4point3: "Post regular updates on the portal effortlessly",
  },
];

export default CaseData;
