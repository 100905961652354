// import images1 from "../../images/inforBanner.png";
const baseUri = process.env.PUBLIC_URL;
const CaseData = [
  {
    pageName: "Fee",
    image1: baseUri + "./images/features/a)Simplified Setup.svg",
    title1: "Simplified Setup",
    Case1point1: "Customizable Installment options",
    Case1point2: "Categorize and manage discounts for students",
    Case1point3: "Create profile-specific fee structures effortlessly",
    image2: baseUri + "./images/features/b)Hassle-Free Fee Collection.svg",
    title2: "Hassle-Free Fee Collection",
    Case2point1: "Secure payment gateways & various modes of payments",
    Case2point2: "Automated bookkeeping of pending and paid fees",
    Case2point3: "Supports offline & online payment methods",
    Case2point4: "Timely reminders & updates on fees via SMS",
    image3: baseUri + "./images/features/c)Data at Your Fingertips.svg",
    title3: "Data at Your Fingertips",
    Case3point1:
      "Easy access to student data, payment status, fee reports, and more",
    Case3point2: "Easily downloadable fee reports",
    Case3point3: "Alert parents on pending dues",
  },
];

export default CaseData;
