import React, { Fragment } from "react";
import ReasonsWhy from "../../components/ReasonsWhy";
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";

const Gallery = () => {
  return (
    <Fragment>
      <BreadCrumb pagename="Why should you choose ScholarErp?" />

      <ReasonsWhy className="hasMb" />
      <Footer />
    </Fragment>
  );
};

export default Gallery;
