import React from "react";
import "./style.scss";
const InnerCaseLeft = (props) => {
  let { img, title, listItem } = props;

  return (
    <section className="software_service_area sec_pad">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ai_img_outer">
            <div className="d-flex align-items-center ai_services">
              <img src={img} alt="LeftImage" />
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div className="testimonial_title ">
              <div className="seo_sec_title wow fadeInUp" data-wow-delay="0.3s">
                <h5 className="usecase_main__title">{title}</h5>
              </div>
              <div className="row pt-3">
                <ul className="list-channel">
                  {listItem &&
                    listItem.map((value, key) => {
                      return (
                        <li
                          key={key}
                          data-wow-delay="0.2s"
                          className="wow fadeInUp"
                          style={{ display: "flex" }}
                        >
                          <div>
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </div>
                          <div>{value.item}</div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default InnerCaseLeft;
