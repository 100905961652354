import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import CaseData from "../CaseData/Lms";
import FeaturesSection from "../FeaturesSection";
import FrequentlyAskedQsn from "../FrequentlyAskedQsn";
import TopSectionTitle from "../TopSectionTitle";
 
import InnerCaseLeft from "../../components/InnerCaseLeft"
import InnerCaseRight from "../../components/InnerCaseRight"
const StudentTracking = () => {
  return (
    <>
      <Suspense fallback={null}>
        {/* <Grid className="" container spacing={4}>
      <Grid item xs={12} md={8}> */}
        <Grid className="textContent"></Grid>
        <div className="content-section-pratner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <TopSectionTitle
                  subtitle=" The Student Tracking System"
                  body="  A transport management system helps you track all your buses
                  and optimize their routes. A school operates a large fleet of
                  buses and it gets difficult to track the real time activities
                  of these buses. Parents these days also want the safety of
                  their children. This is why a transport management system is
                  required which helps the school in generating real time
                  updates to the parents, which eliminates the continuous
                  calling of parents to know about the bus status. A transport
                  management system also helps in minimizing the cost of repairs
                  since it helps in optimizing the bus routes."
                />
            
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="content-section-pratner">
          <h2 className="wow fadeInUp">Overview</h2>
        </div>
        {CaseData &&
          CaseData.map((integrations) => {
            return (
              <InnerCaseLeft
                img={integrations?.image1}
                title={integrations.title3}
                listItem={[
                  integrations.Case3point1 && {
                    item: integrations.Case3point1,
                  },
                  integrations.Case3point2 && {
                    item: integrations.Case3point2,
                  },
                  integrations.Case3point3 && {
                    item: integrations.Case3point3,
                  },
                ]}
              />
            );
          })}
        ;
        {CaseData &&
          CaseData.map((integrations1) => {
            return (
              <InnerCaseRight
                title={integrations1.title4}
                listItem={[
                  integrations1.Case4point1 && {
                    item: integrations1.Case4point1,
                  },
                  integrations1.Case4point2 && {
                    item: integrations1.Case4point2,
                  },
                  integrations1.Case4point3 && {
                    item: integrations1.Case4point3,
                  },
                ]}
                img={integrations1?.image2}
              />
            );
          })}
        ;
        {CaseData &&
          CaseData.map((integrations) => {
            return (
              <InnerCaseLeft
                img={integrations?.image3}
                title={integrations.title3}
                listItem={[
                  integrations.Case3point1 && {
                    item: integrations.Case3point1,
                  },
                  integrations.Case3point2 && {
                    item: integrations.Case3point2,
                  },
                  integrations.Case3point3 && {
                    item: integrations.Case3point3,
                  },
                ]}
              />
            );
          })}
        ;
        {CaseData &&
          CaseData.map((integrations1) => {
            return (
              <InnerCaseRight
                title={integrations1.title4}
                listItem={[
                  integrations1.Case4point1 && {
                    item: integrations1.Case4point1,
                  },
                  integrations1.Case4point2 && {
                    item: integrations1.Case4point2,
                  },
                  integrations1.Case4point3 && {
                    item: integrations1.Case4point3,
                  },
                ]}
                img={integrations1?.image4}
              />
            );
          })}
        <div
          className="w-full flex justify-center lg:justify-start mb-6 mt-6"
          style={{ marginLeft: "40%", marginBottom: "50px" }}
        >
          <Link to="/contact-us" className="cBtn">
            Get Started
          </Link>
        </div>
        <FeaturesSection />
        <FrequentlyAskedQsn />
      </Suspense>
    </>
  );
};

export default StudentTracking;
