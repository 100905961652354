const baseUri = process.env.PUBLIC_URL;

const CaseData = [
  {
    pageName: "StudentInfor",
    image1: baseUri + "./images/features/a)Easy Access.svg",
    title1: "Easy Access",
    Case1point1: "Access student information anytime, anywhere",
    Case1point2: "Manage the entire student directory effortlessly",
    Case1point3: "Update and maintain student information",
    image2: baseUri + "./images/features/b)Effortless Management.svg",
    title2: "Effortless Management",
    Case2point1:
      " Have a bird’s eye view of each & every student’s information",
    Case2point2: "Keep track of & manage student finances",
    Case2point3:
      "Access student records from attendance and academics to pending fees",
    image3: baseUri + "./images/features/c)Better Insights.svg",
    title3: "Better Insights",
    Case3point1: "Create student and teacher profiles for convenience",
    Case3point2:
      "Use behavioral badges & performance reports to assess students",
    Case3point3: "Share important updates through the noticeboard",
    title4: "Analyze attendance trends",
  },
];

export default CaseData;
