import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import { connect } from "react-redux";
import CaseData from "../CaseData/Fee";
import BenefitsSection from "../BenefitsSection";
import FrequentlyAskedQsn from "../FrequentlyAskedQsn";
import { FaRoad } from "react-icons/fa";
import TopSectionTitle from "../TopSectionTitle";
import { MdEmojiTransportation } from "react-icons/md";
import { BsCarFrontFill } from "react-icons/bs";
import { loadServiceAction } from "../../store/actions/action";
import data from "../BenefitsSection/Feedata";
 
import InnerCaseLeft from "../../components/InnerCaseLeft"
import InnerCaseRight from "../../components/InnerCaseRight"
const overviews = [
  {
    title: "Payment Gateway Integration",
    description:
      "Integrated payment gateways to automatically receive payments online & update student payment profile.",
    icon: <BsCarFrontFill />,
  },
  {
    title: "Generate Alerts & Notifications",
    description:
      "Send alerts to notify all the students and their parents before fees are due or for unscheduled fees.",
    icon: <MdEmojiTransportation />,
  },
  {
    title: "Automatic Route Creation",
    description:
      "Uses all the information fed into it to create the perfect route for the school buses based on the number of students automatically.",
    icon: <FaRoad />,
  },
];
const FeesManagement = () => {

  return (
    <>
      <Suspense fallback={null}>
        {/* // <Grid className="" container spacing={4}>
    //   <Grid item xs={12} md={8}> */}
        <Grid className="textContent"></Grid>
        <div className="content-section-pratner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <TopSectionTitle
                  subtitle="What is Fee Management System?"
                  body="    Fee collection is the most tedious yet important task for any
                  educational institution. Managing fees effectively and
                  digitally has become a necessity for any school as it requires
                  keen attention for calculations such as pending fee status,
                  fee concessions, fee defaulters, scholarships, discounts etc.
                  Moreover managing all these records manually does not
                  guarantee 100% accuracy. ScholarErp’s fee management system is
                  100% safe and secure. It allows online & offline fee
                  collection and helps in managing other fee related activities
                  seamlessly. "
                />
              
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="content-section-pratner">
          <h2 className="wow fadeInUp">Overview</h2>
        </div>
        <div className="modulesWrapperr">
          <div className="Container">
            <div id="modules" className="modules-section page-section">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 modules-sec">
                    <div id="mostmodul">
                      <div id="mostmodul">
                        {overviews.map((overview, index) => (
                          <div key={index} className="modulbox">
                            <a
                              className="wow pulse"
                              data-toggle="modal"
                              data-target="#myModal101"
                              href="/"
                            >
                              <div></div>

                              <i className="icon-book-open icons iconss">
                                {overview.icon}
                              </i>
                              <h4>{overview.title}</h4>
                              <span>{overview.description}</span>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BenefitsSection data={data} />
        {CaseData &&  
          CaseData.map((integrations, index) => {
            return (
              <InnerCaseLeft
                key={index}
                img={integrations?.image1}
                title={integrations.title1}
                listItem={[
                  integrations.Case1point1 && {
                    item: integrations.Case1point1,
                  },
                  integrations.Case1point2 && {
                    item: integrations.Case1point2,
                  },
                  integrations.Case1point3 && {
                    item: integrations.Case1point3,
                  },
                ]}
              />
            );
          })}
        ;
        {CaseData &&
          CaseData.map((integrations1, index) => {
            return (
              <InnerCaseRight
                key={index}
                title={integrations1.title2}
                listItem={[
                  integrations1.Case2point1 && {
                    item: integrations1.Case2point1,
                  },
                  integrations1.Case2point2 && {
                    item: integrations1.Case2point2,
                  },
                  integrations1.Case2point3 && {
                    item: integrations1.Case2point3,
                  },
                  integrations1.Case2point4 && {
                    item: integrations1.Case2point4,
                  },
                ]}
                img={integrations1?.image2}
              />
            );
          })}
        ;
        {CaseData &&
          CaseData.map((integrations, index) => {
            return (
              <InnerCaseLeft
                key={index}
                img={integrations?.image3}
                title={integrations.title3}
                listItem={[
                  integrations.Case3point1 && {
                    item: integrations.Case3point1,
                  },
                  integrations.Case3point2 && {
                    item: integrations.Case3point2,
                  },
                  integrations.Case3point3 && {
                    item: integrations.Case3point3,
                  },
                ]}
              />
            );
          })}
        ;
        <div
          className="w-full flex justify-center lg:justify-start mb-6 mt-6"
          style={{ marginLeft: "40%", marginBottom: "50px" }}
        >
          <Link to="/contact-us" className="cBtn">
            Get Started
          </Link>
        </div>
        <FrequentlyAskedQsn />
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    service: state.service,
  };
};
export default connect(mapStateToProps, { loadServiceAction })(FeesManagement);
