import React from "react";

import "./style.scss";
import Accordion from "./Accordian";

const FAQ = () => {
  
  return (
    <div className="faqs">
      <h2>Frequently Asked Questions</h2>
      <Accordion
        title="Why does a school need an online admission management system?"
        content="Using an online admission management system makes the process of admission of students to a school and the subsequent follow-up for the same simple and efficient. In other words, the entire admission process from data collection from the students/parents to the final confirmation of admission can be digitized."
      />
      <Accordion
        title="What happens if a student’s admission is confirmed?"
        content="Once the student goes through all the processes associated with admission, all the information provided by the student will be transferred automatically to the student directory through the online student admission management system. This means that there is no need to enter the details once again."
      />
      <Accordion
        title="How can schools collect admission fees with ScholarErp?"
        content="
        <p>ScholarErp’s student admission management software allows schools to collect fees for admission.</p>
        </br>
        <p>They can collect the amount (the amount of which is decided by the school administration) from the student at 5 different stages - Enquiry, Interested, Confirmed, Rejected, or Shortlisted.</p>
        </br>
        <p>Adding a paywall is one of the most effective ways to filter out candidates who are only partially interested in joining the school.</p>
        "
      />
      <Accordion
        title="Does the online admission management system help in data and document collection?"
        content="ScholarErp’s student admission management software is a platform that can help manage all admission-related processes. Admins can check student inquiries, check their eligibility, collect documents, and manage the entire admission process seamlessly with ScholarErp."
      />
      <Accordion
        title="Can parents access the admission portal?"
        content="Yes, parents can access the admission portal and apply online for admission. They can either fill out the inquiry form or application form (based on their interest) and choose their course of action accordingly."
      />
      <Accordion
        title="How does ScholarErp make enquiry follow-ups easier?"
        content="Once a student has applied for enquiry or admission to the school, they will be contacted by the school to proceed with the process. If the parent/student is busy at the time, they can request for a follow-up. ."
      />
      <Accordion
        title="How does the admission management system accommodate offline leads?"
        content="While a lot of students would opt for applying for admission to a school out of convenience, some other students might persist on applying offline by visiting the campus personally."
      />
    </div>
  );
};



export default FAQ;
