import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import SectionTitle from "../SectionTitle";
import { loadServiceAction } from "../../store/actions/action";
import "./style.scss";
// import { MdHeight } from "react-icons/md";
const baseUri = process.env.PUBLIC_URL;

const products = [
  {
    title: "Scholar ERP Core",
    description:
      "Our flagship product, Scholar ERP Core, is an all-in-one education management system",
    icon: baseUri + "/images/features/ScholarERPCore.svg",
  },
  {
    title: "Scholar CRM",
    description:
      "Scholar CRM is a customer relationship management system designed specifically for educational institutions",
    icon: baseUri + "/images/features/ScholarCRM.svg",
  },
  {
    title: "Scholar Mobile App",
    description:
      "The Scholar Mobile App is a mobile-first platform that allows students, parents, and staff to access important information",
    icon: baseUri + "/images/features/ScholarMobileApp.svg",
  },
  {
    title: "Scholar LMS",
    description:
      "Scholar LMS is a learning management system designed to help institutions deliver online courses and training programs",
    icon: baseUri + "/images/features/ScholarLMS.svg",
  },


  {
    title: "Scholar Consulting",
    description:
      "Our consulting services provide customized solutions for educational institutions looking to optimize their operations ",
    icon: baseUri + "/images/features/ScholarConsulting.svg",
  },
];
const PortfolioList = () => {
   
  return (
    <Grid className="portfolioListArea">
      <SectionTitle
        subtitle="Latest Portfolio"
        title="Some of our Portfolio"
        body="At Scholar ERP, we offer a range of products and services designed to help educational institutions streamline their operations and improve student outcomes. Our comprehensive suite of tools includes: "
      />

      <div className="vc_row wpb_row vc_row-fluid">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="portfolio-area overflow-hidden grid-items casestudy-default-padding">
                <div className="container">
                  <div className="portfolio-items-area">
                    <div className="masonary">
                      <div
                        id="portfolio-grid"
                        className="portfolio-items colums-3"
                        style={{ position: "relative" }}
                      >
                        {products.map((product, index) => (
                          <div key={index} className="pf-item cs-mar-top-60">
                            <Grid className="thumb">
                              <img
                                src={product.icon}
                                alt={product.title}
                              />
                            </Grid>
                            <Grid className="content">
                              <span>{product.name}</span>
                              <h2>
                                <Link to={`/portfolio-single`}>
                                  {product.title}
                                </Link>
                              </h2>
                              <p style={{ width: "90%", height: "120px" }}>
                                {product.description}
                              </p>
                              <Link
                                to={`/portfolio-single`}
                                className="cBtn cBtnRadius cBtnUppercase"
                              >
                                Read More
                              </Link>
                            </Grid>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};
const mapStateToProps = state => {
    return {
        service: state.service
    }
};
export default connect(mapStateToProps, { loadServiceAction })(PortfolioList);

