//default action
export const LOADING = 'LOADING';
export const LOADED = 'LOADED';
export const LOAD_HEADER_SUCCESSFUL = 'LOAD_HEADER_SUCCESSFUL';
export const LOAD_HOME_SUCCESSFUL = 'LOAD_HOME_SUCCESSFUL';
export const LOAD_ABOUT_SUCCESSFUL = 'LOAD_ABOUT_SUCCESSFUL';
export const LOAD_BLOG_SUCCESSFUL = 'LOAD_BLOG_SUCCESSFUL';
export const LOAD_GALLERY_SUCCESSFUL = 'LOAD_GALLERY_SUCCESSFUL';
export const LOAD_TEAM_SUCCESSFUL = 'LOAD_TEAM_SUCCESSFUL';
export const LOAD_SINGLE_BLOG_SUCCESSFUL = 'LOAD_SINGLE_BLOG_SUCCESSFUL';
export const LOAD_SERVICE_SUCCESSFUL = 'LOAD_SERVICE_SUCCESSFUL';
export const LOAD_PORTFOLIO_SUCCESSFUL = 'LOAD_PORTFOLIO_SUCCESSFUL';
export const LOAD_SINGLE_PORTFOLIO_SUCCESSFUL = 'LOAD_SINGLE_PORTFOLIO_SUCCESSFUL';