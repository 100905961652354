import React, { Fragment } from "react";
import InstituteManagement from "../../components/InstituteManagement";
import Footer from "../../components/Footer";
import Banner from "../../components/Banner";

import Image from "../../images/assessBanner.png";

const Gallery = () => {

  return (
    <Fragment>
      <Banner
        subtitle="Institute Enterprise Management "
        title="Institute Enterprise Resource Planning"
        pagedescription="Student performance analysis & report generation to staff attendance & fee management - everything you need to run a school in one place!"
        bannerImg={Image}
      />
      <InstituteManagement className="hasMb" />
      <Footer />
    </Fragment>
  );
};



export default Gallery;
