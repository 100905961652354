import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import { connect } from "react-redux";
import { BsGraphDownArrow } from "react-icons/bs";
import { GiPapers } from "react-icons/gi";
import { BsFileEarmarkBarGraph } from "react-icons/bs";
import CaseData from "../CaseData/StudentInfo";
import FrequentlyAskedQsn from "../../components/FrequentlyAskedQsn"
import FeaturesSection from "../FeaturesSection";
import { loadServiceAction } from "../../store/actions/action";
import TopSectionTitle from "../TopSectionTitle";

 
import InnerCaseLeft from "../../components/InnerCaseLeft"
import InnerCaseRight from "../../components/InnerCaseRight"
const overviews = [
  {
    title: "Minimizes Paperwork",
    description:
      "       Maintaining student’s records involves a lot ofpaperwork as maintaining all the registerscontaining the student information is a hassle.",
    icon: <GiPapers />,
  },
  {
    title: "Easy Reporting",
    description:
      "   ScholarErp helps in providing real-time reportsand insights to the management to make better decisions. ",
    icon: <BsFileEarmarkBarGraph />,
  },
  {
    title: "Better Student Performance",
    description:
      " ScholarErp helps in providing a complete transparency of attendance to the students, which ultimately helps to eliminate the inaccurate time  reporting by the students.",
    icon: <BsGraphDownArrow />,
  },
];

const StudentInfor = () => {

    return (
      <>
        <Suspense fallback={null}>
          {/* // <Grid className="" container spacing={4}>
    //   <Grid item xs={12} md={8}> */}
          <Grid className="textContent"></Grid>
          <div className="content-section-pratner">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <TopSectionTitle
                    subtitle=" What is the Student Information System ?"
                    body="The Student Information system is a system that helps in maintaining all the important records of the students like their personal details, roll number, grades, ID cards, parent details, blood group etc. The Student Information system works as a repository of students where one can find each and every detail related to the student. The Student Information system is also known as the student information system. Student Information system helps integrate parents, teachers, students and the administration."
                  />
                </div>
              </div>
            </div>
            <div className="clear"></div>
          </div>
          <div className="content-section-pratner">
            <h2 className="wow fadeInUp">Overview</h2>
          </div>
          <div className="modulesWrapperr">
            <div className="Container">
              <div id="modules" className="modules-section page-section">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 modules-sec">
                      <div id="mostmodul">
                        <div id="mostmodul">
                          {overviews.map((overview, index) => (
                            <div key={index} className="modulbox">
                              <a
                                className="wow pulse"
                                data-toggle="modal"
                                data-target="#myModal101"
                                href="/"
                              >
                                <div></div>

                                <i className="icon-book-open icons iconss">
                                  {overview.icon}
                                </i>
                                <h4>{overview.title}</h4>
                                <span>{overview.description}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {CaseData &&
            CaseData.map((integrations) => {
              return (
                <InnerCaseLeft
                  img={integrations?.image1}
                  title={integrations.title1}
                  listItem={[
                    integrations.Case1point1 && {
                      item: integrations.Case1point1,
                    },
                    integrations.Case1point2 && {
                      item: integrations.Case1point2,
                    },
                    integrations.Case1point3 && {
                      item: integrations.Case1point3,
                    },
                  ]}
                />
              );
            })}
          ;
          {CaseData &&
            CaseData.map((integrations1) => {
              return (
                <InnerCaseRight
                  title={integrations1.title2}
                  listItem={[
                    integrations1.Case2point1 && {
                      item: integrations1.Case2point1,
                    },
                    integrations1.Case2point2 && {
                      item: integrations1.Case2point2,
                    },
                    integrations1.Case2point3 && {
                      item: integrations1.Case2point3,
                    },
                  ]}
                  img={integrations1?.image2}
                />
              );
            })}
          ;
          {CaseData &&
            CaseData.map((integrations) => {
              return (
                <InnerCaseLeft
                  img={integrations?.image3}
                  title={integrations.title3}
                  listItem={[
                    integrations.Case3point1 && {
                      item: integrations.Case3point1,
                    },
                    integrations.Case3point2 && {
                      item: integrations.Case3point2,
                    },
                    integrations.Case3point3 && {
                      item: integrations.Case3point3,
                    },
                  ]}
                />
              );
            })}
          ;
          <div
            className="w-full flex justify-center lg:justify-start mb-6 mt-6"
            style={{ marginLeft: "40%", marginBottom: "50px" }}
          >
            {/* <div
              class=" tm-bgcr-bl-2 tm-cr-wh-1 w-52 rounded-xl cursor-pointer px-4 py-4 lg:px-6 lg:py-4 text-center"
              id="sp-ips-banner"
              onclick="triggerFormModal('True')"
            > */}
            <Link to="/contact-us" className="cBtn">
              Get Started
            </Link>
          </div>
          <FeaturesSection />
          <FrequentlyAskedQsn />
        </Suspense>
      </>
    );
};

const mapStateToProps = (state) => {
  return {
    service: state.service,
  };
};
export default connect(mapStateToProps, { loadServiceAction })(StudentInfor);
