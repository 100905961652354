import React from "react";
import { connect } from "react-redux";
import { loadServiceAction } from "../../store/actions/action";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import BenefitsSection from "../BenefitsSection";
import FrequentlyAskedQsn from "../FrequentlyAskedQsn";
import { IoIosPeople } from "react-icons/io";
import { HiIdentification } from "react-icons/hi";
import { SlCalender } from "react-icons/sl";
import { MdOutlineContactPhone,MdOutlineInventory,MdLibraryBooks, MdEmojiTransportation} from "react-icons/md";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { SiSemanticscholar } from "react-icons/si";
import data from '../BenefitsSection/Institutedata';

const overviews = [
  {
    title: "ID Card Generator",
    description:
      "Generate & make changes to ID cards as required for students and staff as per requirement , even weekly offs",
    icon: <HiIdentification />,
  },
  {
    title: "School Yearly Calendar Planner",
    description:
      "Plan the academic year in advance including holidays, school events & activities, exams, and even weekly offs",
    icon: <SlCalender />,
  },
  {
    title: "Exam Management",
    description:
      "Download/print report cards with minimum human involvement, and schedule & plan exams for the entire year",
    icon: <MdLibraryBooks />,
  },
  {
    title: "Communication Module",
    description:
      "Conduct relevant polls and surveys & send out announcements & plan exams for the entire year",
    icon: <MdOutlineContactPhone />,
  },
  {
    title: "Inventory Management",
    description:
      "Categorize items as per inconvenience and utility, purchase items as they run out, ",
    icon: <MdOutlineInventory />,
  },
  {
    title: "Human Resource Management",
    description:
      "Manage staff leaves and track their attendance & plan exams for the entire year",
    icon: <BsFillPersonVcardFill />,
  },
  {
    title: "Transport Management",
    description:
      "Conduct relevant polls and surveys & send out announcements & plan exams for the entire year",
    icon: <MdEmojiTransportation />,
  },
  {
    title: "Attendance Management",
    description:
      "Categorize items as per inconvenience and utility, purchase items as they run out,",
    icon: <IoIosPeople />,
  },
  {
    title: "Admission Management",
    description:
      "Manage staff leaves and track their attendance & plan exams for the entire year",
    icon: <SiSemanticscholar />,
  },
];
const InstituteManagement = () => {
    
  return (
    <>
      {/* // <Grid className="" container spacing={4}>
    //   <Grid item xs={12} md={8}> */}
      <Grid className="textContent"></Grid>

      <div className="content-section-pratner">
        <h2 className="wow fadeInUp">Overview</h2>
      </div>
      <div className="modulesWrapperr">
        <div className="Container">
          <div id="modules" className="modules-section page-section">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 modules-sec">
                  <div id="mostmodul">
                    {overviews.map((module, index) => (
                      <div key={index} className="modulbox">
                        <a
                          className="wow pulse"
                          data-toggle="modal"
                          data-target="#myModal101"
                          href="/"
                        >
                          <div></div>

                          <i className="icon-book-open icons iconss">
                            {module.icon}
                          </i>
                          <h4>{module.title}</h4>
                          <span>{module.description}</span>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BenefitsSection data={data} />
      <FrequentlyAskedQsn />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    service: state.service,
  };
};
export default connect(mapStateToProps, { loadServiceAction })(InstituteManagement);
