import React from 'react';
import { Switch } from 'react-router-dom';

// Route Setting
import HomePage from "../HomePage";
import AboutUs from "../AboutUs";
import Services from "../Services";
import TeamPage from "../TeamPage";
import Portfolio from "../Portfolio";
import Gallery from "../Gallery";
import PortfolioSingle from "../PortfolioSingle";
import BlogLists from "../BlogLists";
import BlogSingle from "../BlogSingle";

import ContactUsPage from "../ContactUsPage";
import Admissions from "../Admissions"; 

import Assessment from '../Assessment';  
import LMS from "../LMS";    

import Reports from "../Reports"; 

import StudentInfor from "../StudentInfor";

import ExamPlanner from '../ExamPlanner';
import SchoolSoftware from '../SchoolSoftware';

import FeesManagement from "../FeesManagement";  

import StudentTracking from "../StudentTracking";

import SelfManagedWeb from "../../components/SelfManagedWeb";  


import SmartAttendance from '../SmartAttendance';
import BusTracking from '../BusTracking';


import InstituteManagement from '../InstituteManagement';
import Plans from '../Plans';
import ReasonsWhy from '../ReasonsWhy';
// // Normal Route
import PrivateRoute from "../_PrivateRoute";


function componentShowRender(copm) {
    switch (copm) {
      case "HomePage":
        return HomePage;
      case "AboutUs":
        return AboutUs;
      case "Services":
        return Services;
      case "TeamPage":
        return TeamPage;
      case "Portfolio":
        return Portfolio;
      case "Gallery":
        return Gallery;
      case "BlogLists":
        return BlogLists;
      case "ContactUsPage":
        return ContactUsPage;
      case "Admissions":
        return Admissions;
      case "Assessment":
        return Assessment;
      case "LMS":
        return LMS;
      case "FeesManagement":
        return FeesManagement;
      case "Reports":
        return Reports;
      case "StudentInfor":
        return StudentInfor;
      case "ExamPlanner":
        return ExamPlanner;
      case "StudentTracking":
        return StudentTracking;
      case "SchoolSoftware":
        return SchoolSoftware;
      case "SelfManagedWeb":
        return SelfManagedWeb;
      case "SmartAttendance":
        return SmartAttendance;
      case "BusTracking":
        return BusTracking;
      case "InstituteManagement":
        return InstituteManagement;
      case "Plans":
        return Plans;
      case "ReasonsWhy":
        return ReasonsWhy;

      default:
        return HomePage;
    }
}

export default function Routes(props) {

    return (
      <Switch>
        <PrivateRoute fixHeader={true} exact path="/" component={HomePage} />
        {/* {console.log(props.menus, "props.menus==")}  */}

        {props.menus !== undefined
          ? props.menus.map((menu, i) => {
              return (
                <PrivateRoute
                  key={i}
                  fixHeader={menu.component === "HomePage" ? true : ""} 
                  exact
                  path={`/${menu.slug}`}
                  component={componentShowRender(menu.component)}
                />
              );
            })
          : ""}
        <PrivateRoute
          path="/portfolio-single/:id"
          component={PortfolioSingle}
        />
        <PrivateRoute path="/about-us" component={AboutUs} />
        <PrivateRoute path="/gallery" component={Gallery} />
        <PrivateRoute path="/admissions" component={Admissions} />
        <PrivateRoute path="/team" component={ TeamPage} />
        <PrivateRoute path="/assessment" component={Assessment} />
        <PrivateRoute exact path="/blog" component={BlogLists} />
        <PrivateRoute path="/blog-details/:id" component={BlogSingle} />
        <PrivateRoute path="/services" component={Services} />
        <PrivateRoute path="/contact-us" component={ContactUsPage} />
        <PrivateRoute path="/portfolio" component={Portfolio} />
        <PrivateRoute path="/lms" component={LMS} />
        <PrivateRoute path="/school-software" component={SchoolSoftware} />
        <PrivateRoute path="/exam-planner" component={ExamPlanner} />
        <PrivateRoute path="/student-information" component={StudentInfor} />
        <PrivateRoute path="/reports" component={Reports} />
        <PrivateRoute path="/fees-management" component={FeesManagement} />
        <PrivateRoute path="/student-tracking" component={StudentTracking} />
        <PrivateRoute path="/website-build" component={SelfManagedWeb} />
        <PrivateRoute path="/smart-attendance" component={SmartAttendance} />
        <PrivateRoute path="/bus-tracking" component={BusTracking} />
        <PrivateRoute path="/reasons" component={ReasonsWhy} />
        <PrivateRoute path="/plans" component={Plans} />
        <PrivateRoute path="/institute-erp" component={InstituteManagement} />
        <PrivateRoute exact fixHeader={true} component={HomePage} />
      </Switch>
    );
}
