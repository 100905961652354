import React  from "react";
// import { connect } from "react-redux";
// import {Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import SectionTitle from "../SectionTitle";
// import { loadServiceAction } from "../../store/actions/action";
import "./style.scss";
// import "./simple-line-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BsFillBookFill } from "react-icons/bs";
import {
  MdOutlinePayments,
  MdAssessment,
  MdSpatialTracking,  
} from "react-icons/md";
import {TbReportSearch  } from "react-icons/tb";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { GrCertificate } from "react-icons/gr";
import { IoLibraryOutline } from "react-icons/io5";
import { GiPersonInBed } from "react-icons/gi";
import { BsPeopleFill, BsJournalBookmark } from "react-icons/bs";
import { ImBooks } from "react-icons/im";


const modules = [
  {
    link: "/admissions",
    title: "Admission",
    description: "Description of Module 1",
    icon: <BsFillBookFill />,
  },
  {
    link: "/assessment",
    title: "Assessment",
    description: "Description of Module 2",
    icon: <MdAssessment />,
  },
  {
    link: "/reports",
    title: "Reports",
    description: "Description of Module 3",
    icon: <TbReportSearch />,
  },
  {
    link: "/fees-management",
    title: "Fees Management",
    description: "Description of Module 4",
    icon: <MdOutlinePayments />,
  },
  {
    link: "/student-information",
    title: "Student Information",
    description: "Description of Module 5",
    icon: <HiOutlineInformationCircle />,
  },
  {
    link: "/student-information",
    title: "Certificates",
    description: "Description of Module 6",
    icon: <GrCertificate />,
  },
  {
    link: "/exam-planner",
    title: "Exam Planner",
    description: "Description of Module 7",
    icon: <BsJournalBookmark />,
  },
  {
    link: "/student-tracking",
    title: "Student Tracking",
    description: "Description of Module 8",
    icon: <MdSpatialTracking />,
  },
  {
    link: "/exam-planner",
    title: "Library",
    description: "Description of Module 8",
    icon: <IoLibraryOutline />,
  },
  {
    link: "/fees-management",
    title: "Accounts",
    description: "Description of Module 8",
    icon: <ImBooks />,
  },
  {
    link: "/institute-erp",
    title: "Hostel",
    description: "Description of Module 8",
    icon: <GiPersonInBed />,
  },
  {
    link: "/institute-erp",
    title: "Staff",
    description: "Description of Module 8",
    icon: <BsPeopleFill />,
  },
];

const ModulesSection = () => {
 
  return (
    <Grid className="modulesWrapper">
      <Grid className="container">
        <SectionTitle
          subtitle="Features"
          title={"Suit of modules for 360 degree digitization"}
        />
        <Grid id="modules" className="modules-section page-section">
          <Grid item xs={12} className="modules-sec">
            <Grid className="container">
              <Grid className="row" style={{ justifyContent:"center" }}>
                {" "}
                {modules.map((module, index) => (
                  <Grid key={index} id="mostmodul">
                    <a
                      className="wow pulse"
                      data-toggle="modal"
                      data-target="#myModal101"
                      href={module.link}
                    >
                      <i className="icon-book-open icons">{module.icon}</i>
                      <h4>{module.title}</h4>
                      {/* <span>{module.description}</span> */}
                    </a>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
// const mapStateToProps = (state) => {
//   return {
//     service: state.service,
//   };
// };
export default ModulesSection;


// export default connect(mapStateToProps)(withTranslation("common")(Modules));


