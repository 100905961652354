import React from 'react';
import {connect} from 'react-redux';
import {Grid} from "@material-ui/core";
// images
import iconBg1 from '../../images/iconbg1.png';
import iconBg2 from '../../images/iconbg2.png';
import iconBg3 from '../../images/iconbg3.png';
import iconBg4 from '../../images/iconbg4.png';
import iconBg5 from '../../images/iconbg5.png';
import lineShape from '../../images/linehsape.png';

import './style.scss';
import {loadHomeAction} from "../../store/actions/action";

const DigitalStudio = (props) => {
    const {
        achievement_title,
        achievement_sub_title,
        achievement_des,
        achievement_list1_title,
        achievement_list2_title,
        achievement_list3_title,
        achievement_list4_title,
        achievement_list5_title,
        achievement_list1_count,
        achievement_list2_count,
        achievement_list3_count,
        achievement_list4_count,
        achievement_list5_count,
    } = props.achievements;

    // useEffect(() => {
    //   props.loadHomeAction();
    // }, []);
    return (
      <Grid className="digitalStudio">
        <Grid className="container" container alignItems="center" spacing={4}>
          <Grid item xs={12} md={6}>
            <Grid className="textContent">
              <strong>
                {achievement_sub_title
                  ? achievement_sub_title
                  : "achievements_&_services"}
              </strong>
              <h4>
                {achievement_title
                  ? achievement_title
                  : "The Platform that Brings An Advance Solution to Schools by Enhancing their Work Productivity."}
              </h4>
              <p>
                {achievement_des
                  ? achievement_des
                  : "As a School Management Platform, we provide refine modules through our software for effective management of a school system under which the collective work is done variably on a single platform."}
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid className="digitalStudioContent">
              <div className="dstudioItem">
            
                <img src={iconBg1} alt="" />
                <Grid className="content">
                  <p>
                    {achievement_list1_title
                      ? achievement_list1_title
                      : "Clients"}
                  </p>
                  <h4>
                    {achievement_list1_count ? achievement_list1_count : "1000+"}
                  </h4>
                </Grid>
              </div>
              <div className="dstudioItem">
                <img src={iconBg3} alt="" />
                <Grid className="content">
                  <p>
                    {achievement_list2_title
                      ? achievement_list2_title
                      : "Modules"}
                  </p>
                  <h4>
                    {achievement_list2_count ? achievement_list2_count : "50"}
                  </h4>
                </Grid>
              </div>
              <div className="dstudioItem">
                <img src={iconBg2} alt="" />
                <Grid className="content">
                  <p>
                    {achievement_list3_title
                      ? achievement_list3_title
                      : "Courses"}
                  </p>
                  <h4>
                    {achievement_list3_count ? achievement_list3_count : "20"}
                  </h4>
                </Grid>
              </div>
              <div className="dstudioItem">
                <img src={iconBg4} alt="" />
                <Grid className="content">
                  <p>
                    {achievement_list4_title
                      ? achievement_list4_title
                      : "Downloads"}
                  </p>
                  <h4>
                    {achievement_list4_count ? achievement_list4_count : "5"}
                  </h4>
                </Grid>
              </div>
              <div className="dstudioItem">
                <img src={iconBg5} alt="" />
                <Grid className="content">
                  <p>
                    {achievement_list5_title
                      ? achievement_list5_title
                      : "Programs"}
                  </p>
                  <h4>
                    {achievement_list5_count ? achievement_list5_count : "10"}
                  </h4>
                </Grid>
              </div>
              <img src={lineShape} alt="" className="lineShape" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
};

const mapStataToProps = state => {
    return {
        achievements: state.home
    }
};

export default connect(mapStataToProps, {loadHomeAction})(DigitalStudio);