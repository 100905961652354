import React from "react";
import Grid from "@material-ui/core/Grid";
import SectionTitle from "../SectionTitle";
import "./style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
// import data from './data.json'

const BenefitsSection = ({data}) => {
 
  return (
    <Grid className="modulesWrapper">
      <div className="container">
        <SectionTitle
          subtitle="Benefits"
          title={"Suit of modules for 360 degree digitization"}
        />
        <section className="benefit_sec">
         
            {data.map((item, index) => {
              return (
                <div key={index} className="benefit_single">
                  <div className="bene">
                    <div className="bene_num">{item?.number1}</div>
                    <div className="bene_content">
                      <h4>{item?.title1}</h4>
                      <p>{item?.description1}</p>
                    </div>
                  </div>
                </div>
              );
              })}
        </section>
      </div>
    </Grid>
  );
};

 export default BenefitsSection;
