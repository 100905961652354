const baseUri = process.env.PUBLIC_URL;

const CaseData = [
  {
    pageName: "Lms",
    image1: baseUri + "./images/features/a)Simplified Setup.svg",
    title1: "Modern Classrooms for the Modern Age",
    Case1point1:
      "Tackle classroom engagement with Live Polls & Hand Raise features.",
    Case1point2: "Revisit classes using the Live Class Recording feature.",
    Case1point3: "Manage classroom schedule and timetable effortlessly",
    image2: baseUri + "./images/features/a)Simplified Setup.svg",
    title2: "Effortless Student Engagement",
    Case2point1: " Create, conduct & evaluate tests with ease",
    Case2point2: "Assign and keep track of student progress with homework",
    Case2point3: "Relay relevant information through study materials",
    image3: baseUri + "./images/features/a)Seamless Communication.svg",
    title3: "Seamless Communication",
    Case3point1:
      "Ensure smooth classroom management with private & classroom chat",
    Case3point2: "Real-time notifications & updates",
    Case3point3: "Share important updates through the noticeboard",
    image4:
      baseUri + "./images/features/d)Actionable Analytics & Dashboard.svg",
    title4: "Actionable Analytics & Dashboard",
    Case4point1:
      " Keep track of students in real time with automated attendance",
    Case4point2: "Intricate LMS tools to analyze and assess performance",
    Case4point3:
      "Personalized strength and weakness reports to understand students",
  },
];

export default CaseData;
